import { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';

import { AlertMetric, type AlertConfiguration } from '__generated__/graphql';
import { SelectedOrgType } from 'Apollo/ApolloCache';
import { GET_ALERT_CONFIGURATIONS_QUERY } from '../OrgAlertConfigPage';
import {
  convertTemperature,
  convertUnitOfMeasurePreferenceToTempScaleEnum,
} from 'Utils/conversionTools';
import { TempScaleEnum } from 'Constants/ConversionEnums';

const DELETE_ALERT_CONFIGURATION_MUTATION = gql`
  mutation RemoveAlertConfiguration($accountId: ID!, $alertConfigurationId: ID!) {
    deleteAlertConfiguration(accountId: $accountId, alertConfigurationId: $alertConfigurationId)
  }
`;

type AlertConfigCardTextRowProps = {
  label: string;
  content: string | number | null;
};

function AlertConfigCardTextRow({ label, content }: AlertConfigCardTextRowProps) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (!content) {
    return null;
  }
  return !isMediumScreen ? (
    <TableRow>
      <TableCell width={300}>
        <Typography fontWeight='bold'>{label}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{content}</Typography>
      </TableCell>
    </TableRow>
  ) : (
    <Box
      display='flex'
      flexDirection={isMediumScreen ? 'column' : 'row'}
      gap={1}
      marginBottom={2.5}
    >
      <Typography fontWeight='bold'>{label}:</Typography>
      <Typography>{content}</Typography>
    </Box>
  );
}

type OrgAlertConfigListViewProps = {
  alertConfigurations?: AlertConfiguration[];
  userHasOrgWritePermission: boolean;
  selectedOrg: SelectedOrgType;
};

export default function OrgAlertConfigurationListView({
  alertConfigurations,
  userHasOrgWritePermission,
  selectedOrg,
}: OrgAlertConfigListViewProps) {
  const unitOfMeasurePreference = selectedOrg?.preferences?.unit_of_measure;
  const tempScalePref = convertUnitOfMeasurePreferenceToTempScaleEnum(unitOfMeasurePreference);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [deletingButtonId, setDeletingButtonId] = useState<string | undefined>(undefined);
  const [deleteAlertConfiguration] = useMutation(DELETE_ALERT_CONFIGURATION_MUTATION);
  const { dispatchToast } = useToast();
  const { dispatchModal } = useModal();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDelete = useCallback(
    async (targetID = '', name) => {
      try {
        setIsDisabled(true);
        await deleteAlertConfiguration({
          variables: {
            accountId: selectedOrg?.id || '',
            alertConfigurationId: targetID,
          },
          refetchQueries: [GET_ALERT_CONFIGURATIONS_QUERY],
        });
        const notifConfig = {
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Alert Configuration Deleted',
          message: `Successfully removed ${name} Alert Configuration`,
        };
        dispatchToast(notifConfig);
        setIsDisabled(false);
      } catch (error) {
        const notifConfig = {
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Error Deleting Alert Configuration',
          message: `Error deleting ${name} Alert Configuration. Please try again.`,
        };
        dispatchToast(notifConfig);
        console.error('ERROR: DELETE_ALERT_CONFIGURATION_MUTATION - ', error);
        setIsDisabled(false);
      }
      setDeletingButtonId(undefined);
    },
    [deleteAlertConfiguration, setDeletingButtonId, dispatchToast, selectedOrg]
  );

  const getAlertThresholdWithLabel = (alertMetric: AlertMetric, value: number) => {
    if (alertMetric === AlertMetric.Temp) {
      let degreesLabel = 'C';
      if (tempScalePref === TempScaleEnum.FAHRENHEIT) {
        degreesLabel = 'F';
      } else if (tempScalePref === TempScaleEnum.KELVIN) {
        degreesLabel = 'K';
      }
      // eslint-disable-next-line no-magic-numbers
      return `${convertTemperature(value, tempScalePref).toPrecision(4)} °${degreesLabel}`;
    }
    if (alertMetric === AlertMetric.Humidity) {
      return `${value}%`;
    }
    if (alertMetric === AlertMetric.Co2) {
      return `${value} ppm`;
    }
    if (alertMetric === AlertMetric.Voc) {
      return `${value} ppm`;
    }
    return `${value}`;
  };

  const alertConfigDeleteHandler = useCallback(
    (
      alertConfig: AlertConfiguration,
      cardContentList: Array<{ label: string; content?: number | string | null }>
    ) => {
      dispatchModal({
        type: ModalNotificationTypeEnum.CONFIRM_DELETE_ALERT_CONFIG,
        modalProps: {
          onConfirm: () => {
            setDeletingButtonId(alertConfig.id);
            return handleDelete(alertConfig.id, alertConfig.metric);
          },
          isLoading: deletingButtonId === alertConfig.id,
          alertTitle: `${alertConfig.metric} Alert on ${cardContentList[0].content} has ${
            cardContentList.splice(-1)[0].content
          } alerts so far`,
        },
      });
    },
    [dispatchModal, deletingButtonId, handleDelete]
  );

  if (!alertConfigurations || alertConfigurations?.length === 0) {
    return (
      <Typography fontWeight='bold' color='primary.secondary'>
        There currently are no Alert Configurations defined available for this organization
      </Typography>
    );
  }

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {alertConfigurations?.map((alertConfig: AlertConfiguration) => {
        const cardContentList = [
          { label: 'Location', content: alertConfig.parentLocation?.name ?? 'Unknown' },
          {
            label: 'High Threshold',
            content: alertConfig.metricThreshold.high
              ? getAlertThresholdWithLabel(alertConfig.metric, alertConfig.metricThreshold.high)
              : 'N/A',
          },
          {
            label: 'Low Threshold',
            content: alertConfig.metricThreshold.low
              ? getAlertThresholdWithLabel(alertConfig.metric, alertConfig.metricThreshold.low)
              : 'N/A',
          },
          {
            label: 'Minimum Exceeded Duration',
            content: `${alertConfig.exceedingDurationMinutes} minutes`,
          },
          {
            label: 'Email Recipients',
            content:
              alertConfig.emailRecipients && alertConfig.emailRecipients.length > 0
                ? alertConfig.emailRecipients.join(', ')
                : 'None',
          },
          { label: 'Created', content: new Date(alertConfig.createdAt).toLocaleDateString() },
          { label: 'Total Alerts Generated', content: alertConfig.totalAlerts ?? 0 },
        ];
        return (
          <Card key={alertConfig.id} elevation={0} variant='outlined'>
            <CardHeader
              title={`${alertConfig.metric} Alert`}
              titleTypographyProps={{ color: 'primary.main', variant: 'h6' }}
              action={
                userHasOrgWritePermission &&
                (isSmallScreen ? (
                  <IconButton
                    disabled={isDisabled}
                    onClick={() => alertConfigDeleteHandler(alertConfig, cardContentList)}
                    color='error'
                  >
                    {deletingButtonId === alertConfig.id ? (
                      <CircularProgress color='error' size={20} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                ) : (
                  <Button
                    disabled={isDisabled}
                    onClick={() => alertConfigDeleteHandler(alertConfig, cardContentList)}
                    color='error'
                    variant='contained'
                    endIcon={deletingButtonId === alertConfig.id && <CircularProgress size={20} />}
                  >
                    Delete
                  </Button>
                ))
              }
            />
            <CardContent sx={{ paddingTop: 0.5 }}>
              <Table>
                <TableBody>
                  {cardContentList.map((row) => {
                    return (
                      <AlertConfigCardTextRow
                        key={row.label}
                        label={row.label}
                        content={row.content}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
}
