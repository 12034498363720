import { useState } from 'react';

export type LocationItemType = {
  name: string;
  id: string;
};

export type LinkedLocationListType = Array<LocationItemType>;

export default function useLinkedLocationList(
  rootLocation: LocationItemType,
  existingSelectedLocations?: LinkedLocationListType
) {
  const rootLocationID = rootLocation?.id;
  const [selectedLocation, setSelectedLocation] = useState<LocationItemType | null>(null);
  const [linkedLocationList, setLinkedLocationList] = useState<LinkedLocationListType>(() => {
    if (existingSelectedLocations && existingSelectedLocations.length !== 0) {
      const preSelectedLocation = existingSelectedLocations[existingSelectedLocations.length - 1];
      setSelectedLocation(preSelectedLocation);
      return existingSelectedLocations;
    } else if (rootLocation) {
      setSelectedLocation(rootLocation);
      return [rootLocation];
    } else {
      return [];
    }
  });

  const addLocation = (id: string, name: string, parentLocationIndex: number): void => {
    if (id === rootLocationID) {
      // Reset the list to only feature root location
      setLinkedLocationList([rootLocation]);
      setSelectedLocation(rootLocation);
      return;
    }
    const locationList = [...linkedLocationList];
    let newLocation;
    let newLocationIndex;
    // if id is the same as the parent location we know that "All locations"
    // has been selected, thus we update the location list to reflect this by
    // resetting the end of the list to the parent location.
    // else we add a new location to the correct poisition in the hierarchy.
    if (id === locationList[parentLocationIndex].id) {
      const { name: parentName, id: parentID } = locationList[parentLocationIndex];
      newLocation = { name: parentName, id: parentID };
      newLocationIndex = parentLocationIndex;
    } else {
      newLocation = { name, id };
      newLocationIndex = parentLocationIndex + 1;
    }

    locationList[newLocationIndex] = newLocation;

    // Remove any locations after the location we just added
    const trimmedList = locationList.slice(0, newLocationIndex + 1);
    setSelectedLocation(newLocation);
    setLinkedLocationList(trimmedList);
  };

  const addLocations = (locations: { id: string; name: string }[]) => {
    setSelectedLocation(locations[locations.length - 1]);
    setLinkedLocationList(locations);
  };

  const removeLocation = (id: string): void => {
    // We can only delete locations with no sub-locations in practice this means that
    // locations targeted for removal will awlways be at the end of the list
    if (id === rootLocationID) {
      // root location cannot be removed
      return;
    }
    const updatedLocationList = [...linkedLocationList];
    updatedLocationList.pop();
    const updatedSelectedLocation = updatedLocationList[updatedLocationList.length - 1];
    setSelectedLocation(updatedSelectedLocation);
    setLinkedLocationList(updatedLocationList);
  };

  return {
    addLocation,
    linkedLocationList,
    removeLocation,
    selectedLocation,
    addLocations,
  };
}
