import { useMemo } from 'react';
import { useQuery, useReactiveVar, gql } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { LocationItemType } from './useLinkedLocationList';

export const GET_INIT_LOCATION_PATH = gql`
  query getInitLocationPath($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      name
      id
      fullLocationPath {
        name
        id
      }
    }
  }
`;

const LOCATION_NOT_FOUND_ERR = 'Location not found';
const LOCATION_INVALID_ID = 'invalid ID format';

export default function useCurrentLocationList(
  locationId?: string | null
): [boolean, LocationItemType[]] {
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const {
    loading,
    data: locationData,
    error: locationError,
  } = useQuery(GET_INIT_LOCATION_PATH, {
    skip: !locationId || locationId === selectedOrg?.rootLocation?.id,
    variables: {
      accountId: selectedOrg?.id ?? '',
      locationId: locationId as string,
    },
  });
  const initLocationList = useMemo(() => {
    const { name, id } = locationData?.location ?? {};
    const locationPathList = locationData?.location?.fullLocationPath;
    let initLocationList_: LocationItemType[] = [];
    if (locationPathList?.length) {
      initLocationList_ = [...locationPathList, { name, id }] as LocationItemType[];
    } else {
      initLocationList_ = [
        {
          name: selectedOrg?.rootLocation?.name ?? 'All Locations',
          id: selectedOrg?.rootLocation?.id as string,
        },
      ];
    }
    if (locationError?.message && [LOCATION_NOT_FOUND_ERR, LOCATION_INVALID_ID].includes(locationError?.message)) {
      // If location not found in the org, remove query params
      window.location.href = window.location.pathname;
    }
    return initLocationList_;
  }, [locationData, selectedOrg, locationError]);
  return [loading, initLocationList];
}
