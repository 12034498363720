import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import DomainIcon from '@mui/icons-material/Domain';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import darkModeUVAngelLogo from 'assets/UVAngel-wordmark-reversed.svg';
import lightModeUVAngelLogo from 'assets/UVAngel-wordmark-full-color.svg';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';

type Props = {
  orgLogoImgUrl: string | null | undefined;
  gridConfig: GridConfigType;
};

export default function FacilityLogoContainer({ orgLogoImgUrl, gridConfig }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const defaultUVAngelLogo = isDarkMode ? darkModeUVAngelLogo : lightModeUVAngelLogo;
  const title = 'Facility';
  const [imgSrc, setImgSrc] = useState<string>(orgLogoImgUrl ?? defaultUVAngelLogo);

  useEffect(() => {
    setImgSrc(orgLogoImgUrl ?? defaultUVAngelLogo);
  }, [orgLogoImgUrl, defaultUVAngelLogo]);

  const onImageLoadError = () => {
    setImgSrc(defaultUVAngelLogo);
  };

  return (
    <QuickMetricsCard
      icon={<DomainIcon sx={{ color: metricCardIconColor }} />}
      title={title}
      gridConfig={gridConfig}
    >
      <Box
        component='img'
        sx={{
          maxHeight: '80%',
          width: '80%',
          aspectRatio: 'auto',
          margin: 'auto',
        }}
        alt='Facility Logo'
        // we pass in a uvAngel logo if custom org logo is not set
        src={imgSrc}
        onError={onImageLoadError}
      />
    </QuickMetricsCard>
  );
}
