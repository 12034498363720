/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Check, Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {
  selectedOrgVar,
  setDefaultOrgVar,
  setSelectedLocationVar,
  setSelectedOrgVar,
} from 'Apollo/ApolloCache';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { truncateString } from 'Utils/stringManipulation';
import { GET_AVAILABLE_ACCOUNTS_QUERY, UPDATE_USER_ORG } from './graphql';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};
const maxResults = 10;

export default function SwitchOrgModal({ onModalClose, modalProps }: Props) {
  const { origin } = modalProps as {
    origin: 'switch-session-org' | 'switch-user-org';
  };
  const navigate = useNavigate();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [nextToken, setNextToken] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orgList, setOrgList] = useState<any[]>([]);
  const [pageRange, setPageRange] = useState({ start: 0, end: maxResults });
  const [page, setPage] = useState<number>(0);
  const [maxPageRequested, setMaxPageRequested] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [updateUserPrefOrgId, setUpdateUserPrefOrgId] = useState<string>();
  const {
    data,
    loading: isLoading,
    error: queryError,
    refetch,
  } = useQuery(GET_AVAILABLE_ACCOUNTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const nextToken = data?.accounts?.nextToken;
      setNextToken(nextToken);
      if (orgList.length === 0) {
        // loads the first list of orgs to init the component
        const initOrgList = data?.accounts?.accounts;
        setOrgList(initOrgList);
      }
    },
    variables: {
      pagination: {
        maxResults,
        nextToken: '',
      },
      searchString: '',
    },
  });
  const [updateUserOrg, _] = useMutation(UPDATE_USER_ORG);
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  if (queryError) {
    showErrorBoundary(queryError);
  }

  const selectedOrgIdFromLocalStorage = useMemo(() => {
    let selectedId: string | null = null;
    if (origin === 'switch-session-org') {
      selectedId = localStorage.getItem('SELECTED_ORGANIZATION_ID');
    } else if (origin === 'switch-user-org') {
      selectedId = localStorage.getItem('SELECTED_USER_ORGANIZATION_ID');
    }
    if (!selectedId) {
      selectedId = selectedOrg?.id as string | null;
    }
    return selectedId;
  }, [origin, selectedOrg]);

  const handleClearURLParams = () => {
    navigate({ pathname: window.location.pathname });
  };

  const handleSetSelectedOrg = (org) => {
    const { id } = org;
    // must explicitly set the id property otherwise apollo throws a warning.
    setSelectedOrgVar({ id, ...org });
  };

  const handleSetDefaultSelectedOrg = (org) => {
    const { id } = org;
    // must explicitly set the id property otherwise apollo throws a warning.
    setDefaultOrgVar({ id, ...org });
  };
  const handleSetSelectedLocation = (org) => {
    const rootLocationID = org?.rootLocation?.id ?? '';
    const rootLocationName = org?.rootLocation?.name ?? '';
    setSelectedLocationVar({
      name: rootLocationName ?? 'All Locations',
      id: rootLocationID,
    });
  };

  const handleFetchNextGroupOfOrgItems = async (
    searchString?: string,
    resetToken?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const res = await refetch({
      pagination: {
        maxResults,
        nextToken: resetToken ?? nextToken,
      },
      searchString: searchString ?? '',
    });
    return res;
  };

  const handleChangePage = async (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const nextButtonClicked = newPage > page;
    if (nextButtonClicked) {
      setPageRange((prevRange) => {
        return {
          start: prevRange.end,
          end: prevRange.end + maxResults,
        };
      });
      // we check to see if we need fetch more pages from server.
      if (newPage > maxPageRequested) {
        const res = await handleFetchNextGroupOfOrgItems(searchString);
        const updatedOrgList = res?.data?.accounts?.accounts;
        setOrgList((prev) => [...prev, ...updatedOrgList]);
      }
    } else {
      // back button was clicked
      setPageRange((prevRange) => {
        return {
          start: prevRange.start - maxResults,
          end: prevRange.start,
        };
      });
    }
    if (newPage > maxPageRequested) {
      setMaxPageRequested(newPage);
    }
    setPage(newPage);
  };

  // Must wrap debounce in useCallback. Otherwise React loses connection to the original
  // debounce func ref. This is needed order for prev debounce requests to be overriden
  // when the input changes before the elapsed time.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(async (searchString: string) => {
      const resetToken = '';
      const res = await handleFetchNextGroupOfOrgItems(searchString, resetToken);
      const updatedOrgList = res?.data?.accounts?.accounts;
      setPageRange({ start: 0, end: maxResults });
      setPage(0);
      setMaxPageRequested(0);
      setOrgList(updatedOrgList);
    }, 1000),
    []
  );

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchString(inputValue);
    setNextToken('');
    debouncedRefetch(inputValue);
  };
  const totalCountOfOrgItemsAvailableOnServer = data?.accounts?.totalCount;
  const orgListSliced = orgList.slice(pageRange.start, pageRange.end);
  const numberOfEmptyRowsToAdd = maxResults - orgListSliced.length;
  const hasNoData = numberOfEmptyRowsToAdd === maxResults;
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        {origin === 'switch-session-org' ? (
          <Stack gap={0.5}>
            <Typography variant='h6'>Select Organization for current session</Typography>
            <Typography variant='body2' color='grey'>
              Only applicable to current session. Changes are reverted back to default if you log
              out or close the tab
            </Typography>
          </Stack>
        ) : (
          <Stack gap={0.5}>
            <Typography variant='h6'>Select Default Organization</Typography>
            <Typography variant='body2' color='grey'>
              Changes are permanent accross different sessions even if you log out.
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <InputBase
          sx={{
            width: '100%',
            bgcolor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
            padding: 1,
            borderRadius: 1,
          }}
          disabled={isLoading}
          onChange={handleOnInputChange}
          placeholder='Enter text to search for a particular organization'
          value={searchString}
          startAdornment={
            <SearchIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                marginRight: 1,
              }}
            />
          }
        />

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
          paddingY={2}
          gap={2}
          height='100%'
          width='100%'
        >
          {isLoading ? (
            <CircularProgress size={100} />
          ) : hasNoData ? (
            <Stack direction='column' alignItems='center' gap={1} marginTop={5}>
              <Typography fontSize={30} fontWeight='bold'>
                No Results
              </Typography>
              <Typography fontSize={20}>Please try searching for another organization</Typography>
            </Stack>
          ) : (
            <TableContainer
              sx={{ maxHeight: 500, overflowY: 'auto', scrollbarWidth: 'slim', marginBottom: 2 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell># Members</TableCell>
                    <TableCell># Locations</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgListSliced.map((org) => {
                    const { id, name, numMembers, numLocations } = org ?? {};
                    const formattedName = truncateString(name, 30);
                    return (
                      <TableRow key={id}>
                        <TableCell width={375} sx={{ whiteSpace: 'nowrap' }}>
                          {formattedName}
                        </TableCell>
                        <TableCell>{numMembers}</TableCell>
                        <TableCell>{numLocations}</TableCell>
                        <TableCell>
                          {id === selectedOrgIdFromLocalStorage ? (
                            <Box
                              display='flex'
                              justifyContent='flex-start'
                              alignItems='center'
                              gap={1}
                            >
                              <Check
                                color='success'
                                sx={{
                                  height: 24,
                                  width: 24,
                                }}
                              />
                              <Typography color='success' variant='button'>
                                Selected
                              </Typography>
                            </Box>
                          ) : origin === 'switch-session-org' ? (
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                localStorage.setItem('SELECTED_ORGANIZATION_ID', id);
                                // we must clear any url params if they exist. Otherwise when the page reloads
                                // with the new org it may utilize stale URL params.
                                handleClearURLParams();
                                handleSetSelectedOrg(org);
                                handleSetSelectedLocation(org);
                                onModalClose();
                              }}
                            >
                              Select
                            </Button>
                          ) : (
                            <Button
                              endIcon={
                                updateUserPrefOrgId === (id as string | undefined) && (
                                  <CircularProgress size='sm' />
                                )
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setUpdateUserPrefOrgId(id);
                                updateUserOrg({
                                  variables: {
                                    preferences: {
                                      defaultAccountId: id,
                                    },
                                  },
                                })
                                  .then(() => {
                                    localStorage.setItem('SELECTED_USER_ORGANIZATION_ID', id);
                                    localStorage.setItem('SELECTED_ORGANIZATION_ID', id);
                                    // we must clear any url params if they exist. Otherwise when the page reloads
                                    // with the new org it may utilize stale URL params.
                                    handleClearURLParams();
                                    handleSetSelectedOrg(org);
                                    handleSetSelectedLocation(org);
                                    handleSetDefaultSelectedOrg(org);
                                  })
                                  .finally(() => {
                                    setUpdateUserPrefOrgId(void 0);
                                    onModalClose();
                                  });
                              }}
                            >
                              Select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter sx={{ position: 'absolute', bottom: 0, right: 24 }}>
                  <TableRow>
                    <TablePagination
                      count={totalCountOfOrgItemsAvailableOnServer}
                      onPageChange={handleChangePage}
                      page={page}
                      rowsPerPage={maxResults}
                      rowsPerPageOptions={[]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
