/* eslint-disable no-magic-numbers */
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

import {
  EditLocation,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Group,
  Groups as GroupsIcon,
  Info,
  IntegrationInstructions,
  Key,
  NotificationsActive,
  WorkspacePremium,
} from '@mui/icons-material';
import ContinuumAppPageEnums from 'Constants/ContinuumAppPagesEnums';
import RoutePaths from 'Constants/RoutePaths';
import { NavLink } from 'react-router-dom';

type OrgMenuItemConfigType = {
  primaryText: string;
  path: RoutePaths;
  pageType: ContinuumAppPageEnums;
  icon: JSX.Element;
  disabled?: boolean;
};

const {
  PATH_ORGANIZATION_DETAILS,
  PATH_ORGANIZATION_API_KEYS,
  PATH_ORGANIZATION_ALERT_CONFIGS,
  PATH_ORGANIZATION_INTEGRATIONS,
  PATH_ORGANIZATION_LOCATION_EDITOR,
  PATH_ORGANIZATION_SUBSCRIPTION,
  PATH_ORGANIZATION_USERS,
} = RoutePaths;

const {
  ORG_DETAILS_PAGE,
  ORG_API_KEY_LIST_PAGE,
  ORG_ALERT_CONFIGURATION_LIST_PAGE,
  ORG_INTEGRATIONS_PAGE,
  ORG_USERS_PAGE,
  ORG_LOCATION_EDITOR_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PAGE,
} = ContinuumAppPageEnums;

const orgMenuItemsConfig: Array<OrgMenuItemConfigType> = [
  {
    primaryText: 'Details',
    path: PATH_ORGANIZATION_DETAILS,
    pageType: ORG_DETAILS_PAGE,
    icon: <Info />,
  },
  {
    primaryText: 'API keys',
    path: PATH_ORGANIZATION_API_KEYS,
    pageType: ORG_API_KEY_LIST_PAGE,
    icon: <Key />,
  },
  {
    primaryText: 'Integrations',
    path: PATH_ORGANIZATION_INTEGRATIONS,
    pageType: ORG_INTEGRATIONS_PAGE,
    icon: <IntegrationInstructions />,
  },
  {
    primaryText: 'Users',
    path: PATH_ORGANIZATION_USERS,
    pageType: ORG_USERS_PAGE,
    icon: <Group />,
  },
  {
    primaryText: 'Location Editor',
    path: PATH_ORGANIZATION_LOCATION_EDITOR,
    pageType: ORG_LOCATION_EDITOR_PAGE,
    icon: <EditLocation />,
  },
  {
    primaryText: 'Subscription',
    path: PATH_ORGANIZATION_SUBSCRIPTION,
    pageType: ORG_LOCATION_SUBSCRIPTION_PAGE,
    icon: <WorkspacePremium />,
  },
  {
    primaryText: 'Alerts',
    path: PATH_ORGANIZATION_ALERT_CONFIGS,
    pageType: ORG_ALERT_CONFIGURATION_LIST_PAGE,
    icon: <NotificationsActive />,
  },
].filter((tabs) => !process.env.REACT_APP_HIDDEN_TABS?.split(',').includes(tabs.path));
type Props = {
  handleToggleSideBar: () => void;
  currentPage: ContinuumAppPageEnums;
};

export default function OrganizationListItem({ currentPage, handleToggleSideBar }: Props) {
  const sideBarTextColor = 'white';

  const [isOrgSubMenuOpen, setIsOrgSubMenuOpen] = useState<boolean>(() => {
    if (
      currentPage === ORG_DETAILS_PAGE ||
      currentPage === ORG_API_KEY_LIST_PAGE ||
      currentPage === ORG_ALERT_CONFIGURATION_LIST_PAGE ||
      currentPage === ORG_INTEGRATIONS_PAGE ||
      currentPage === ORG_USERS_PAGE ||
      currentPage === ORG_LOCATION_EDITOR_PAGE ||
      currentPage === ORG_LOCATION_SUBSCRIPTION_PAGE
    ) {
      return true;
    }
    return false;
  });

  const OrgMenuItem = ({ config }: { config: OrgMenuItemConfigType }): JSX.Element => {
    const { primaryText, path, icon, disabled } = config;
    const handleClick = () => {
      handleToggleSideBar();
    };
    return (
      <NavLink to={path} style={{ color: 'inherit', textDecoration: 'none' }} end>
        {({ isActive }) => (
          <ListItemButton
            disabled={disabled}
            selected={isActive}
            onClick={handleClick}
            sx={{ pl: 4 }}
          >
            <ListItemIcon
              sx={{
                color: sideBarTextColor,
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              sx={{
                color: sideBarTextColor,
              }}
              primary={primaryText}
              secondary={disabled ? 'Coming Soon' : null}
              secondaryTypographyProps={{
                color: 'inherit',
              }}
            />
          </ListItemButton>
        )}
      </NavLink>
    );
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setIsOrgSubMenuOpen(!isOrgSubMenuOpen)}>
          <ListItemIcon
            sx={{
              color: sideBarTextColor,
            }}
          >
            {<GroupsIcon />}
          </ListItemIcon>
          <ListItemText
            primary='Organization'
            sx={{
              color: sideBarTextColor,
            }}
          />
          {isOrgSubMenuOpen ? (
            <ExpandLessIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={isOrgSubMenuOpen}>
        <List disablePadding>
          {orgMenuItemsConfig.map((config, index) => {
            return <OrgMenuItem key={index} config={config} />;
          })}
        </List>
      </Collapse>
    </>
  );
}
