import { gql, useQuery } from '@apollo/client';
import RoomIcon from '@mui/icons-material/Room';
import { useTheme, Avatar, Button, Grid, Stack, Typography } from '@mui/material';

import type { Alerts as AlertsType } from '__generated__/graphql';
import { cache } from 'Apollo/ApolloCache';
import QuickMetricsCard, { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import RoutePaths from 'Constants/RoutePaths';
import moment from 'moment-timezone';
import { Link as RouterLink } from 'react-router-dom';

const GET_LOCATIONS_NEEDING_ATTENTION_QUERY = gql(`
query getLocationsNeedingAttention($input: GetLocationsNeedingAttentionInput!) {
    report {
      uvangel {
        getLocationsNeedingAttention(input: $input) {
          locationAlerts {
            alerts {
              highCO2 {
                alert
              }
              highHumidity {
                alert
              }
              highIAQ {
                alert
              }
              quanta {
                alert
              }
              temp {
                alert
              }
            }
            location {
              name
              id
            }
          }
        }
      }
    }
  }
`);

type Props = {
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
};

type LocationRowItemProps = {
  name: string | undefined;
  locationId: string | undefined;
  alerts: AlertsType | null | undefined;
};

function findAlertKeys(alerts) {
  const results: string[] = [];

  if (alerts) {
    for (const key in alerts) {
      if (alerts[key]?.alert === true) {
        results.push(key);
      }
    }
  }

  return results;
}

export default function LocationsNeedingAttenionPanel({
  startDate,
  endDate,
  selectedLocationID,
  selectedOrgID,
  gridConfig,
}: Props) {
  const theme = useTheme();
  const {
    data,
    loading: isLoading,
    error: locationsNeedingAttentionError,
  } = useQuery(GET_LOCATIONS_NEEDING_ATTENTION_QUERY, {
    variables: {
      input: {
        targetAvgQuantaThreshold: 100,
        thresholdCO2: 800,
        thresholdHumidity: 70,
        thresholdIAQ: 100,
        thresholdTemperatureC: 27,
        timeRange: {
          startDate,
          endDate,
        },
        rootLocationId: selectedLocationID ?? '',
        accountId: selectedOrgID ?? '',
      },
    },
  });
  function LocationRowItem({ name, locationId, alerts }: LocationRowItemProps) {
    const alertsList = findAlertKeys(alerts);
    const alertsText = alertsList
      .map((alert) => {
        if (alert.startsWith('high')) {
          // perform some cleanup on the values
          const alertText = alert.split('high');
          if (alertText.length > 1) {
            alert = alertText[1];
          }
        }
        return alert.charAt(0).toUpperCase() + alert.slice(1);
      })
      .join(', ');

    return (
      <Button component={RouterLink} to={`${RoutePaths.PATH_REPORTS}?locationID=${locationId}`}>
        <Stack alignItems='flex-start' direction='row' gap={2} width='100%'>
          <Avatar>
            <RoomIcon />
          </Avatar>
          <Stack alignItems='start' justifyContent='start'>
            <Typography lineHeight={1.25} fontWeight='medium'>
              {name}
            </Typography>
            <Typography lineHeight={1.25} color='text.secondary'>{`${alertsText}`}</Typography>
          </Stack>
        </Stack>
      </Button>
    );
  }
  const locationData = data?.report?.uvangel?.getLocationsNeedingAttention.locationAlerts ?? [];
  const title = 'Locations Needing Attention';
  const infoText = 'Locations in an elevated risk state for any reason will show up here.';
  const selectedLocation = cache.readFragment<{ id: string; name: string }>({
    id: `Location:${selectedLocationID}`,
    fragment: gql`
      fragment MyLocation on Location {
        id
        name
      }
    `,
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');
  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={
        <RoomIcon
          sx={{
            color: locationsNeedingAttentionError ? theme.palette.error.main : metricCardIconColor,
          }}
        />
      }
      title={title}
      infoText={infoText}
      gridConfig={gridConfig}
      error={!!locationsNeedingAttentionError}
    >
      <Stack justifyContent='flex-start' maxHeight='95%' width='100%' overflow='auto'>
        {locationData.length === 0 ? (
          <>
            <Typography alignContent={'center'} color={'InactiveCaptionText'}>
              No data available for <b>{selectedLocation?.name}</b>{' '}
              <i>
                in the last{' '}
                <b>
                  {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
                </b>
              </i>
            </Typography>
          </>
        ) : (
          <Grid container spacing={2}>
            {locationData.map((data) => {
              const { id, name } = data?.location ?? {};
              const { alerts } = data ?? {};
              return (
                <Grid key={id} item>
                  <LocationRowItem locationId={id} alerts={alerts} name={name} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Stack>
    </QuickMetricsCard>
  );
}
