// IAQMetricContainer

/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import FactoryIcon from '@mui/icons-material/Factory';
import type { GaugeConfigType } from 'Constants/OverViewTypes';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { cache } from 'Apollo/ApolloCache';
import { useTheme, Typography } from '@mui/material';
import moment from 'moment-timezone';

const { red, yellow, green } = gaugeColors;

type Props = {
  iaqDataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};

export default function IAQMetricContainer({
  iaqDataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: Props) {
  const theme = useTheme();

  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();

  const title = 'IAQ';
  const infoText = 'IAQ for this location';
  const minValue = 0;
  const maxValue = 400;

  useEffect(() => {
    const report = iaqDataToAverage ?? [];
    const iaq = report.length === 0 ? null : Math.floor(report[report.length - 1] ?? 0);
    const selectedToolTipText = `${iaq} iaq`;

    const rangeConfig = [
      {
        defaultColor: green.default,
        selectedColor: green.selected,
        limit: 100,
        tooltip: {
          defaultText: 'Good IAQ range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: yellow.default,
        selectedColor: yellow.selected,
        limit: 200,
        tooltip: {
          defaultText: 'Moderately polluted IAQ range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: red.default,
        selectedColor: red.selected,
        limit: 400,
        tooltip: {
          defaultText: 'Heavilly polluted IAQ range',
          selectedText: selectedToolTipText,
        },
      },
    ];

    const mainGaugeLabel = `${iaq} iaq`;

    setGaugeConfig({
      rangeConfig,
      mainGaugeLabel,
      value: iaq,
      minValue,
      maxValue,
    });
  }, [iaqDataToAverage]);
  const selectedLocation = cache.readFragment<{ id: string; name: string }>({
    id: `Location:${selectedLocationID}`,
    fragment: gql`
      fragment MyLocation on Location {
        id
        name
      }
    `,
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');

  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={<FactoryIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />}
      title={title}
      infoText={infoText}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.IAQ}`}
      gridConfig={gridConfig}
      error={error}
    >
      {gaugeConfig ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig || (gaugeConfig && !gaugeConfig.value)) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
