import {
  useTheme,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import InfoIcon from '@mui/icons-material/Info';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { Link as RouterLink } from 'react-router-dom';
import { Error as ErrorIcon } from '@mui/icons-material';

const DEFAULT_CARD_HEIGHT = 325;

export type GridConfigType = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

type Props = {
  children?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  infoText?: string;
  title?: string;
  isLoading?: boolean;
  onReportsIconClick?: () => void;
  link?: string;
  linkTooltip?: string;
  gridConfig?: GridConfigType;
  elevation?: number;
  error?: boolean;
};

export default function QuickMetricsCard({
  children,
  isLoading = false,
  icon,
  title,
  infoText,
  link,
  linkTooltip,
  gridConfig = {},
  elevation = 0,
  error = false,
}: Props) {
  const theme = useTheme();
  function CustomPaperWrapper({ children, ...restProps }) {
    return (
      <Paper elevation={elevation} {...restProps}>
        {children}
      </Paper>
    );
  }

  return (
    <Grid item {...gridConfig}>
      <Stack
        sx={{
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: error ? theme.palette.error.main : 'transparent',
        }}
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        component={CustomPaperWrapper}
        height={DEFAULT_CARD_HEIGHT}
        minWidth={250}
        maxWidth={700}
        width='100%'
        padding={2}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height={20}
          width='100%'
        >
          <Stack alignItems='center' direction='row' gap={1.5}>
            {icon ?? null}
            <Typography fontSize={16} color={error ? theme.palette.error.main : 'inherit'}>
              {title}
            </Typography>
          </Stack>
          <Stack alignItems='center' direction='row' gap={1.5}>
            {link && !error && (
              <Tooltip title={linkTooltip ?? 'Click to view a detailed report'}>
                <RouterLink to={link}>
                  <IconButton>
                    <AssessmentIcon sx={{ color: metricCardIconColor }} />
                  </IconButton>
                </RouterLink>
              </Tooltip>
            )}
            {error ? (
              <Tooltip title='An error occurred. Try selecting a new location or requesting data over a smaller range.'>
                <ErrorIcon sx={{ color: theme.palette.error.main }} />
              </Tooltip>
            ) : (
              infoText && (
                <Tooltip title={infoText}>
                  <InfoIcon sx={{ color: metricCardIconColor }} />
                </Tooltip>
              )
            )}
          </Stack>
        </Stack>
        <Stack flexDirection='column' justifyContent='center' alignItems='center' height='100%'>
          {isLoading ? <CircularProgress size={75} /> : children}
        </Stack>
      </Stack>
    </Grid>
  );
}
