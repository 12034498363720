/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Autocomplete, Box, Chip, IconButton, InputBase, Stack, Tab, Tabs, Typography, autocompleteClasses, styled, useMediaQuery, useTheme } from '@mui/material';
import { Business, Close as CloseIcon, Map, MeetingRoom, Place, Search as SearchIcon } from '@mui/icons-material';
import { LocationArchetype, LocationBySearchStringQuery } from '__generated__/graphql';
import { gql } from '__generated__';
import debounce from 'debounce';

export const SEARCH_LOCATION_QUERY = gql(`
  query locationBySearchString($accountId: ID!, $searchString: String!) {
    locations(accountId: $accountId, searchString: $searchString) {
      id
      name
      tags
      description
      archetype
      fullLocationPath {
        id
        name
        __typename
      }
      __typename
    }
  }
`);

interface LocationSearchProps {
  selectedOrgId?: string;
  setLocationSearchResults: (locations: LocationBySearchStringQuery['locations']) => void;
  addLocations: (locations: { id: string; name: string }[]) => void;
}

const DEBOUNCE_WAIT = 500;

interface PopperComponentProps {
  anchorEl?: unknown;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

function a11yProps(index: number) {
  return {
    id: `location-tab-${index}`,
    'aria-controls': `location-tabpanel-${index}`,
  };
}

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export default function LocationSearch({
  selectedOrgId,
  setLocationSearchResults,
  addLocations
}: LocationSearchProps) {

  const [searchString, setSearchString] = useState('');
  const [suggestedLocations, setSuggestedLocations] = useState<LocationBySearchStringQuery['locations']>();
  const [suggestedBuildings, setSuggestedBuildings] = useState<LocationBySearchStringQuery['locations']>();
  const [suggestedFloors, setSuggestedFloors] = useState<LocationBySearchStringQuery['locations']>();
  const [suggestedRooms, setSuggestedRooms] = useState<LocationBySearchStringQuery['locations']>();
  const [searchLocations, { loading, data }] = useLazyQuery(SEARCH_LOCATION_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tabValue, setTabValue] = useState(0);
  const [shouldShowNoOptions, setShouldShowNoOptions] = useState(false);

  useEffect(() => {
    setLocationSearchResults(data?.locations);
  }, [data, setLocationSearchResults]);

  useEffect(() => {
    if (!data || !data.locations) {
      setSuggestedLocations(null);
      setSuggestedBuildings(null);
      setSuggestedFloors(null);
      setSuggestedRooms(null);
    }
    const buildings: LocationBySearchStringQuery['locations'] = [];
    const floors: LocationBySearchStringQuery['locations'] = [];
    const rooms: LocationBySearchStringQuery['locations'] = [];
    data?.locations?.forEach(location => {
      switch (location?.archetype) {
        case LocationArchetype.Building:
          buildings.push(location);
          break;
        case LocationArchetype.Floor:
          floors.push(location);
          break;
        case LocationArchetype.Room:
          rooms.push(location);
          break;
        default:
          break;
      }
    });
    setSuggestedLocations(data?.locations);
    setSuggestedBuildings(buildings);
    setSuggestedFloors(floors);
    setSuggestedRooms(rooms);
    setShouldShowNoOptions(true);
  }, [data, setSuggestedLocations, setSuggestedBuildings, setSuggestedFloors, setSuggestedRooms]);

  const searchLocationsHandlerDebounced = useMemo(
    () => debounce(searchLocations, DEBOUNCE_WAIT),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setSuggestedLocations([]);
    setSuggestedBuildings([]);
    setSuggestedFloors([]);
    setSuggestedRooms([]);
    setTabValue(0);
    setShouldShowNoOptions(false);

    if (!searchString) {
      setLocationSearchResults(undefined);
      return;
    }

    searchLocationsHandlerDebounced({
      variables: {
        searchString: searchString,
        accountId: selectedOrgId ?? ''
      }
    });
  }, [searchString, searchLocationsHandlerDebounced, selectedOrgId, setLocationSearchResults]);

  const selectLocationHandler = useCallback(
    (location: ArrayElement<NonNullable<LocationBySearchStringQuery['locations']>>) => {
      const allLocations = [
        ...(location?.fullLocationPath ?? []),
        { name: location?.name ?? '', id: location?.id ?? '', __typename: location?.__typename },
      ];
      addLocations(allLocations?.map((loc) => ({ name: loc?.name ?? '', id: loc?.id ?? '' })));
    },
    [addLocations]
  );

  return (
    <Autocomplete
      open
      fullWidth
      freeSolo
      onInputChange={(e, value) => {
        setSearchString(value);
      }}
      ListboxProps={{
        sx: {
          maxHeight: isMobile ? '80vh' : '50vh',
        }
      }}
      autoHighlight
      PopperComponent={PopperComponent}
      renderTags={() => null}
      loading={loading}
      inputValue={searchString}
      renderOption={(props, option) => {
        if (typeof option === 'string') {
          return (
            <Box component={'li'} {...props} key={'no-options'}>
              {option}
            </Box>
          );
        }
        return (
          <Box component={'li'} {...props} key={option?.id}>
            {option?.archetype === LocationArchetype.Building ? <Business /> : null}
            {option?.archetype === LocationArchetype.Floor ? <Map /> : null}
            {option?.archetype === LocationArchetype.Room ? <MeetingRoom /> : null}
            {(!option?.archetype || option?.archetype === LocationArchetype.Root) ? <Place /> : null}
            <Box
              key={option?.id}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-start'
              marginLeft={1}
            >
              <Typography fontWeight='bold' color='gray' variant='subtitle2'>
                {option?.fullLocationPath?.map((loc) => loc?.name).join(' / ')}
              </Typography>
              <Typography variant='h6'>{option?.name}</Typography>
              <Typography variant='caption'>{option?.description}</Typography>
              <Stack sx={{ alignItems: 'end' }} direction='row' spacing={1} flexWrap='wrap'>
                {option?.tags?.map(tag => <Chip key={option?.id + tag} label={tag} size='small' variant='outlined' />)}
              </Stack>
            </Box>
          </Box>
        );
      }}
      groupBy={() => ''}
      renderGroup={(params) => (
        <Box key={params.key} sx={{ display: isMobile ? null : 'flex' }}>
          <Tabs
            value={tabValue}
            onChange={(_, v) => {
              setTabValue(v);
            }}
            aria-label='location-tabs'
            orientation={isMobile ? 'horizontal' : 'vertical'}
            sx={{ overflowX: 'auto', scrollbarWidth: 'none', scrollbarGutter: 'none', borderRight: isMobile ? 0 : 1, borderBottom: isMobile ? 1 : 0, borderColor: 'divider' }}
          >
            <Tab icon={<Chip icon={<Place />} label={suggestedLocations?.length} />} label='All' {...a11yProps(0)}/>
            <Tab icon={<Chip icon={<Business />} label={suggestedBuildings?.length} />} label='Buildings' {...a11yProps(1)} />
            <Tab icon={<Chip icon={<Map />} label={suggestedFloors?.length} />} label='Floors' {...a11yProps(2)} />
            <Tab icon={<Chip icon={<MeetingRoom />} label={suggestedRooms?.length} />} label='Rooms' {...a11yProps(3)} />
          </Tabs>
          <Box sx={{ width: '100%' }}>
            {params.children}
          </Box>
        </Box>
      )}
      onChange={(e, selectedLocation) => {
        if (typeof selectedLocation === 'string') {
          return;
        }
        selectLocationHandler(selectedLocation);
      }}
      disableCloseOnSelect
      options={suggestedLocations ?? []}
      getOptionLabel={(option) => typeof option === 'string' ? option : option?.name ?? 'unknown'}
      getOptionDisabled={(option) => !option?.id}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filterOptions={(options: any[]) => { // has to be 'any' type to allow for appending `No Matching Locaitons` on there
        if (tabValue === 1) {
          options = options.filter(o => o?.archetype === LocationArchetype.Building);
        }
        if (tabValue === 2) {
          options = options.filter(o => o?.archetype === LocationArchetype.Floor);
        }
        if (tabValue === 3) {
          options = options.filter(o => o?.archetype === LocationArchetype.Room);
        }
        if (shouldShowNoOptions && options.length === 0) {
          options.push('No Matching Locations');
        }
        return options;
      }}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          fullWidth
          placeholder="Search Locations"
          sx={{ fontSize: 30, padding: '16px 24px', borderBottom: 1, borderColor: theme.palette.divider }}
          startAdornment={
            <SearchIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                marginRight: 1,
              }}
              fontSize='inherit'
            />
          }
          endAdornment={
            searchString && searchString.length > 0 ?
              <IconButton
                onClick={() => {
                  setSearchString('');
                }}
              >
                <CloseIcon fontSize='inherit'/>
              </IconButton>
              : null
          }
        />
      )}
    />
  );
}
