import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useCallback } from 'react';

import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';

import { Check as CheckIcon, ContentCopy as CopyIcon } from '@mui/icons-material';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import type {
  CreateLocationMutation,
  GetLocationEditorDataQuery,
  GetNewFileUploadUrlQuery,
  GetNewFileUploadUrlQueryVariables,
  UpdateLocationFloorPlanImageMutation,
  UpdateLocationFloorPlanImageMutationVariables,
} from '__generated__/graphql';
import ImageRendererWithUpload from 'Components/SharedUI/ImageRendererWithUpload';
import useCopy from 'Hooks/useCopy';
import type { LinkedLocationListType } from 'Hooks/useLinkedLocationList';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import {
  GET_FILE_UPLOAD_URL,
  getFormattedFileType,
  uploadImageToS3,
} from '../OrgDetailsPage/OrgProfilePhotoRendererContainer';

type LocationType = GetLocationEditorDataQuery['location'];

export const UPDATE_FLOOR_PLAN = gql`
  mutation UpdateLocationFloorPlanImage(
    $accountId: ID!
    $locationId: ID!
    $floorPlanImageId: String
  ) {
    updateLocation(
      accountId: $accountId
      locationId: $locationId
      floorPlanImageId: $floorPlanImageId
    ) {
      id
      floorPlanImageUrl
    }
  }
`;

type Props = {
  locationData: LocationType;
  selectedOrgID: string;
  onUpdateFloorPlan: () => void;
  onUpdateLinkedLocationList: (id: string, name: string, parentLocationIndex: number) => void;
  linkedLocationList: LinkedLocationListType;
  onRemoveLocation: (id: string) => void;
};

export default function LocationDetaillsCard({
  locationData,
  selectedOrgID,
  onRemoveLocation,
  onUpdateLinkedLocationList,
  onUpdateFloorPlan,
  linkedLocationList,
}: Props) {
  const {
    archetype,
    name,
    type,
    description,
    tags,
    floorPlanImageUrl,
    parentLocation,
    metadata: metaData,
    numDevices,
    numImmediateSubLocations,
    id: selectedLocationID,
  } = locationData ?? {};

  const locationHasDevices = numDevices != null && numDevices > 0;
  const locationHasSubLocations = numImmediateSubLocations != null && numImmediateSubLocations > 0;
  const isDeleteButtonDisabled = locationHasDevices || locationHasSubLocations;
  // if parent location is falsy we assume the location is a root location.
  // we should look at the archetype field instead once all root locations archetypes are backfilled.
  const isRootLocation = parentLocation == null;
  const { dispatchModal } = useModal();
  const { dispatchToast } = useToast();
  const [copied, copyText] = useCopy();

  const [updateFloorPlanImage, { loading: floorPlanUpdateLoading }] = useMutation<
    UpdateLocationFloorPlanImageMutation,
    UpdateLocationFloorPlanImageMutationVariables
  >(UPDATE_FLOOR_PLAN);
  const [getFileUploadUrl] = useLazyQuery<
    GetNewFileUploadUrlQuery,
    GetNewFileUploadUrlQueryVariables
  >(GET_FILE_UPLOAD_URL);
  const deleteLocationFloorPlanImage = useCallback(async () => {
    try {
      await updateFloorPlanImage({
        variables: {
          accountId: selectedOrgID,
          locationId: selectedLocationID as string,
          floorPlanImageId: null,
        },
      });
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Floor Plan Removed',
        message: 'You have successfully removed your floor plan',
      });
    } catch (err: unknown) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Removing Floor Plan',
        message: 'Please try again',
      });
    } finally {
      onUpdateFloorPlan();
    }
  }, [dispatchToast, onUpdateFloorPlan, selectedLocationID, selectedOrgID, updateFloorPlanImage]);
  const uploadAndUpdateLocationFloorPlan = useCallback(
    async (file: File) => {
      try {
        const fileUploadDataRes = await getFileUploadUrl({
          variables: {
            input: {
              accountId: selectedOrgID,
              filesizeBytes: file?.size,
              filetype: getFormattedFileType(file?.type ?? ''),
            },
          },
        });
        const uploadURL = fileUploadDataRes?.data?.getNewFileUploadUrl?.uploadUrl ?? '';
        const newS3FileID = fileUploadDataRes?.data?.getNewFileUploadUrl?.fileId ?? '';
        await uploadImageToS3(uploadURL, file);
        await updateFloorPlanImage({
          variables: {
            accountId: selectedOrgID,
            locationId: selectedLocationID as string,
            floorPlanImageId: newS3FileID,
          },
        });
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Floor Plan Updated',
          message: 'You have successfully updated your floor plan',
        });
      } catch (error: unknown) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Error Updating Floor Plan',
          message: 'Please try again',
        });
      } finally {
        onUpdateFloorPlan();
      }
    },
    [
      dispatchToast,
      getFileUploadUrl,
      onUpdateFloorPlan,
      selectedLocationID,
      selectedOrgID,
      updateFloorPlanImage,
    ]
  );

  return (
    <Stack width='100%' spacing={2}>
      {archetype?.toLocaleLowerCase() === 'floor' && (
        <Fragment>
          <Typography color='textSecondary' variant='h5'>
            Floor Plan
          </Typography>
          <ImageRendererWithUpload
            userHasEditPermission
            imageURL={floorPlanImageUrl}
            size='xlarge'
            loading={floorPlanUpdateLoading}
            callBack={() =>
              dispatchModal({
                type: ModalNotificationTypeEnum.IMAGE_UPLOAD_MODAL,
                modalProps: {
                  title: 'Upload Floor Plan',
                  imageUploadCallBack: uploadAndUpdateLocationFloorPlan,
                },
              })
            }
            onDelete={() => deleteLocationFloorPlanImage()}
          />
        </Fragment>
      )}
      <Typography variant='h5' color='textSecondary'>
        Location Details
      </Typography>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Name</Typography>
              <Typography>{name}</Typography>
            </Stack>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Location ID</Typography>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>{selectedLocationID}</Typography>
                <Tooltip title={copied ? 'Copied' : 'Copy Location ID'}>
                  {copied ? (
                    <IconButton
                      onClick={(e) => e.preventDefault}
                      size='small'
                      sx={{ pl: 0, ml: 0 }}
                    >
                      <CheckIcon color='success' fontSize='small' sx={{ pl: 0, ml: 0 }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => copyText(e, selectedLocationID ?? '')}
                      size='small'
                      sx={{ pl: 0, ml: 0 }}
                    >
                      <CopyIcon fontSize='small' sx={{ pl: 0, ml: 0 }} />
                    </IconButton>
                  )}
                </Tooltip>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Type</Typography>
              <Typography>{type}</Typography>
            </Stack>
            {archetype && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Archetype</Typography>
                <Typography>{archetype}</Typography>
              </Stack>
            )}
            {description && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Description</Typography>
                <Typography>{description}</Typography>
              </Stack>
            )}
            {tags && tags.length > 0 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Tags</Typography>
                <Stack gap={1} direction='row' alignItems='center'>
                  {tags.map((tag, index) => {
                    return <Chip key={index} label={tag} />;
                  })}
                </Stack>
              </Stack>
            )}
            {metaData?.airVolumeFt3 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Air Volume</Typography>

                <Typography>{metaData.airVolumeFt3}</Typography>
              </Stack>
            )}
            {metaData?.assumedAch && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Assummed ACH</Typography>

                <Typography>{metaData.assumedAch}</Typography>
              </Stack>
            )}
            {metaData?.desiredQuantaThresholdCm3 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Quanta Threshold</Typography>

                <Typography>{metaData.desiredQuantaThresholdCm3}</Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
        <CardActions sx={{ mt: 2 }}>
          <Stack
            width='100%'
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='flex-end'
          >
            <Tooltip
              arrow
              title={
                isDeleteButtonDisabled
                  ? 'Locations with sub-locations and or devices can not be removed.'
                  : ''
              }
            >
              <span>
                <Button
                  disabled={isDeleteButtonDisabled}
                  color='error'
                  variant='contained'
                  disableElevation
                  onClick={() =>
                    dispatchModal({
                      type: ModalNotificationTypeEnum.REMOVE_LOCATION_CONFIRMATION_MODAL,
                      modalProps: {
                        accountId: selectedOrgID,
                        locationId: selectedLocationID,
                        name,
                        onRemoveLocation,
                        parentLocationId:
                          // eslint-disable-next-line no-magic-numbers
                          linkedLocationList[linkedLocationList.length - 2]?.id ?? '',
                      },
                    })
                  }
                >
                  Delete
                </Button>
              </span>
            </Tooltip>
            <Button
              color='info'
              variant='contained'
              onClick={() =>
                dispatchModal({
                  type: ModalNotificationTypeEnum.EDIT_LOCATION_DETAILS_MODAL,
                  modalProps: {
                    archetype,
                    accountId: selectedOrgID,
                    description,
                    isRootLocation,
                    locationId: selectedLocationID,
                    name,
                    tags,
                    type,
                    metaData,
                  },
                })
              }
            >
              Edit
            </Button>
            <Button
              color='success'
              variant='contained'
              onClick={() =>
                dispatchModal({
                  type: ModalNotificationTypeEnum.ADD_SUB_LOCATION_MODAL,
                  modalProps: {
                    accountId: selectedOrgID,
                    selectedLocationID: selectedLocationID,
                    onSuccess: (newLocation: CreateLocationMutation['createLocation']) => {
                      if (!newLocation) return;
                      const parentLocationIndex =
                        // eslint-disable-next-line no-magic-numbers
                        linkedLocationList.length - 1;
                      onUpdateLinkedLocationList(
                        newLocation.id,
                        newLocation.name,
                        parentLocationIndex
                      );
                      onUpdateFloorPlan();
                    },
                  },
                })
              }
            >
              Add Location
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Stack>
  );
}
