/* eslint-disable indent */
import { gql, useQuery } from '@apollo/client';
import { CircularProgress, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  GetAllEnvOverviewDataQuery,
  GetAllEnvOverviewDataQueryVariables,
  TimeInterval,
} from '__generated__/graphql';
import type { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import AirTreatmentCardContainer from './DashboardCardContainers/AirTreatmentCardContainer';
import CO2SmallCardContainer from './DashboardCardContainers/CO2Card/CO2SmallCardContainer';
import ConnectedDevicesCardContainer from './DashboardCardContainers/ConnectedDevicesCardContainer';
import FacilityLogoContainer from './DashboardCardContainers/FacilityLogoContainer';
import IAQMetricContainer from './DashboardCardContainers/IAQMetricContainer';
import LocationHumidityCardContainer from './DashboardCardContainers/LocationHumidityCardContainer';
import LocationsNeedingAttenionPanel from './DashboardCardContainers/LocationsNeedingAttenionPanel';
import LocationTemperatureCardContainer from './DashboardCardContainers/LocationTemperatureCardContainer';
import MoldRiskCardContainer from './DashboardCardContainers/MoldRiskCardContainer';
import PowerConsumptionCardContainer from './DashboardCardContainers/PowerConsumptionCardContainer';
import VOCMetricCardContainer from './DashboardCardContainers/VOCMetricContainer';

export const GET_ENV_DATA_QUERY = gql`
  query GetAllEnvOverviewData($accountId: ID!, $locationId: ID!, $timeFrame: TimeFrameInput!) {
    report {
      uvangel {
        activelyConnectedDevices(accountId: $accountId, locationId: $locationId) {
          x
          y
        }
        environmentalMetricsReportByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          y {
            temp
            humidity
            voc
            iaq
            co2
          }
        }
      }
    }
  }
`;

type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};

export default function OverviewPage({ selectedOrg, selectedLocation }: Props) {
  // default range is past hour
  const selectedOrgID = selectedOrg?.id ?? '';
  const rootLocationID = selectedOrg?.rootLocation?.id;
  const orgLogoImgUrl = selectedOrg?.preferences?.logo_image_url ?? undefined;

  const [searchParams, setSearchParams] = useSearchParams();
  const locationIDFromQueryParams = searchParams.get('locationID');

  const [selectedLocationID, setSelectedLocationID] = useState(() => {
    if (locationIDFromQueryParams) {
      return locationIDFromQueryParams;
    } else if (selectedLocation) {
      return selectedLocation.id;
    } else {
      return rootLocationID;
    }
  });

  // eslint-disable-next-line no-magic-numbers
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(1, 'hour'), []);

  const [isLoading, initLocationList] = useCurrentLocationList(selectedLocationID);

  const {
    data: envData,
    loading: isLoadingEnvData,
    error: envError,
  } = useQuery<GetAllEnvOverviewDataQuery, GetAllEnvOverviewDataQueryVariables>(
    GET_ENV_DATA_QUERY,
    {
      variables: {
        accountId: selectedOrgID ?? '',
        locationId: selectedLocationID ?? '',
        timeFrame: {
          startDate,
          endDate,
          timeInterval: TimeInterval.Yearly,
        },
      },
    }
  );

  useEffect(() => {
    setSearchParams({ locationID: selectedLocationID ?? '' }, { replace: true });
  }, [selectedLocationID, setSearchParams]);

  const handleOnLocationChange = (id: string) => {
    setSelectedLocationID(id);
    setSearchParams({ locationID: id }, { replace: true });
  };

  const theme = useTheme();
  const isBelowLargeBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));

  const flexDirection = isBelowLargeBreakpoint ? 'column' : 'row';
  const alignItems = isBelowLargeBreakpoint ? 'center' : '';

  const smallCardGridConfig = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 3,
  };
  return (
    <PageContainerFrame pageTitles={['Overview']}>
      <Stack
        alignItems={alignItems}
        flexDirection={flexDirection}
        gap={1}
        width='100%'
        maxWidth={2500}
      >
        {!!rootLocationID && !!selectedLocationID && !isLoading ? (
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <LocationSelectorBreadcrumb
                preLoadedLocationList={initLocationList}
                onLocationSelectCallback={handleOnLocationChange}
              />
            </Grid>
            <FacilityLogoContainer orgLogoImgUrl={orgLogoImgUrl} gridConfig={smallCardGridConfig} />
            <ConnectedDevicesCardContainer
              data={envData?.report?.uvangel?.activelyConnectedDevices}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />

            <LocationTemperatureCardContainer
              tempDataToAverage={envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
                (d) => d?.y?.temp
              )}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />
            <LocationHumidityCardContainer
              humidityDataToAverage={envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
                (d) => d?.y?.humidity
              )}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />
            <CO2SmallCardContainer
              co2DataToAverage={envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
                (d) => d?.y?.co2
              )}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />
            <VOCMetricCardContainer
              vocDataToAverage={envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
                (d) => d?.y?.voc
              )}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />
            <IAQMetricContainer
              iaqDataToAverage={envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
                (d) => d?.y?.iaq
              )}
              isLoading={isLoadingEnvData}
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
              error={!!envError}
            />

            <MoldRiskCardContainer
              selectedLocation={{
                id: selectedLocationID,
                name: selectedLocation?.name ?? 'All Locations',
              }}
              selectedOrgId={selectedOrgID}
              gridConfig={smallCardGridConfig}
            />
            {/* <FacilityHealthScoreCardContainer
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={smallCardGridConfig}
            /> */}
            <LocationsNeedingAttenionPanel
              startDate={startDate}
              endDate={endDate}
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}
            />
            <AirTreatmentCardContainer
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 8,
              }}
            />
            <PowerConsumptionCardContainer
              selectedLocationID={selectedLocationID}
              selectedOrgID={selectedOrgID}
              gridConfig={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            />
          </Grid>
        ) : (
          <CircularProgress size={50} />
        )}
      </Stack>
    </PageContainerFrame>
  );
}
