import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  Stack,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
  InputLabel,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  FormHelperText,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { hoursMap } from 'Components/AuthedPages/InfectionPage/PageViews/InfectionFilters';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TimeInterval } from '__generated__/graphql';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import { calculateDateRange } from 'Components/SharedUI/DateRangePicker';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import dayjs from 'dayjs';

import SubLocationFilterOptionsContainer from './AdvancedFilters/SubLocationFilterOptionsContainer';
import LocationDevicesOptionsContainer from './AdvancedFilters/LocationDevicesOptionsContainer';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import { useSearchParams } from 'react-router-dom';
import { TempScaleEnum } from 'Constants/ConversionEnums';
import type { Dayjs as DayjsType } from 'dayjs';
import { currentUserDataVar } from 'Apollo/ApolloCache';
import { useReactiveVar } from '@apollo/client';
import { manualIntervalOptions as generateManualIntervalOptions } from 'Utils/manageIntervals';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';

const { Hourly, PerMinute, Daily, Weekly, Monthly } = TimeInterval;
const { CO2, HUMIDITY, IAQ, TEMP, VOC } = MetricReportTypeEnum;

export const intervalLabels = {
  [PerMinute]: 'Per Minute',
  [Hourly]: 'Hourly',
  [Daily]: 'Daily',
  [Weekly]: 'Weekly',
  [Monthly]: 'Monthly',
};

const { FAHRENHEIT, CELSIUS } = TempScaleEnum;

enum RefreshIntervalOptionsEnum {
  EVERY_MINUTE = 'Every minute',
  EVERY_FIVE_MIN = 'Every 5 minutes',
  EVERY_TEN_MIN = 'Every 10 minutes',
  EVERY_THIRTY_MIN = 'Every 30 minutes',
  EVERY_HOUR = 'Every hour',
}

const refreshIntervals = Object.keys(RefreshIntervalOptionsEnum);

type Props = {
  isLoading: boolean;
  handleRefetchQuery?: () => void;
  selectedLocationID: string;
  selectedOrgID: string;
  cancelRefetch: () => void;
  resetRefreshInteval: () => void;
  handleSetRefreshInterval: (inteval: number) => void;
  refreshInterval: number | '';
};

export default function ReportSideBarPanel({
  isLoading,
  selectedLocationID,
  selectedOrgID,
  cancelRefetch,
  resetRefreshInteval,
  handleSetRefreshInterval,
  refreshInterval,
}: Props) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const [searchParams, setSearchParams] = useSearchParams();

  const startDate = searchParams.get('startDate') as string;
  const endDate = searchParams.get('endDate') as string;

  const selectedDevicesRaw = searchParams.get('selectedDevices');
  const subLocationsListRaw = searchParams.get('selectedSubLocations');

  const [currentParams, setCurrentParams] = useState(Object.fromEntries(searchParams.entries()));
  const [customStartDate, setCustomStartDate] = useState<DayjsType | null>(() => {
    if (currentParams.selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return dayjs(startDate);
    } else {
      return null;
    }
  });
  const [customEndDate, setCustomEndDate] = useState<DayjsType | null>(() => {
    if (currentParams.selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return dayjs(endDate);
    } else {
      return null;
    }
  });
  const [shouldShowRefreshOptions, setShouldShowRefreshOptions] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<string[]>(
    !selectedDevicesRaw ? [] : JSON.parse(selectedDevicesRaw)
  );
  const [selectedSubLocations, setSelectedSubLocations] = useState<string[]>(
    !subLocationsListRaw ? [] : JSON.parse(subLocationsListRaw)
  );
  const hasSelectedDevices = selectedDevices.length > 0;
  const hasSelectedSubLocations = selectedSubLocations.length > 0;
  const [shouldShowAdvancedFilters, setShouldShowAdvancedFilters] = useState<boolean>(() => {
    return hasSelectedSubLocations || hasSelectedDevices;
  });
  const [shouldShowSubLocationFilter, setShouldShowSubLocationFilter] =
    useState<boolean>(hasSelectedSubLocations);
  const [shouldShowDevicesFilter, setShouldShowDevicesFilter] =
    useState<boolean>(hasSelectedDevices);

  const manualIntervalOptions = useMemo(() => {
    if (currentParams.selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return generateManualIntervalOptions(
        customStartDate?.toISOString() ?? startDate,
        customEndDate?.toISOString() ?? endDate
      );
    } else {
      const hoursDiff = hoursMap[currentParams.selectedTimePeriod];
      const [strt, end] = getPastDateTimeRange(hoursDiff, 'hours');
      return generateManualIntervalOptions(strt, end);
    }
  }, [currentParams.selectedTimePeriod, customStartDate, startDate, customEndDate, endDate]);

  useEffect(() => {
    if (
      manualIntervalOptions.includes(currentParams.selectedInterval as TimeInterval) ||
      currentParams.selectedInterval === 'Auto'
    )
      return;
    setCurrentParams((prev) => ({ ...prev, selectedInterval: 'Auto' }));
  }, [manualIntervalOptions, currentParams.selectedInterval]);
  useEffect(() => {
    setCurrentParams(Object.fromEntries(searchParams.entries()));
    if (searchParams.get('startDate')) {
      setCustomStartDate(dayjs(searchParams.get('startDate')));
    }
    if (searchParams.get('endDate')) {
      setCustomEndDate(dayjs(searchParams.get('endDate')));
    }
    if (searchParams.get('selectedSubLocations')) {
      setSelectedSubLocations(
        !searchParams.get('selectedSubLocations')
          ? []
          : JSON.parse(searchParams.get('selectedSubLocations') || '')
      );
    }
    if (searchParams.get('selectedDevices')) {
      setSelectedDevices(
        !searchParams.get('selectedDevices')
          ? []
          : JSON.parse(searchParams.get('selectedDevices') || '')
      );
    }
  }, [searchParams]);

  const handleIntervalChange = (event) => {
    setCurrentParams({ ...currentParams, selectedInterval: event.target.value });
  };

  const handleMetricChange = (event) => {
    const updatedParams = {
      ...currentParams,
      selectedMetric: event.target.value,
    };
    setCurrentParams(updatedParams);
  };

  const getCurrentDateRange = (dateRange: DateRangeEnum) => {
    if (dateRange === DateRangeEnum.CUSTOM) {
      // we have a custom time period selected, no need to re-calculate the date range
      return {
        startDate: currentParams.startDate,
        endDate: currentParams.endDate,
      };
    }

    // re-calculate date range and return active values
    return calculateDateRange(dateRange as DateRangeEnum);
  };

  const handleTimePeriodChange = (event) => {
    const selectedTimePeriod = event.target.value;
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      const updatedParams = {
        ...currentParams,
        selectedTimePeriod,
      };
      delete updatedParams['startDate'];
      delete updatedParams['endDate'];
      setCustomStartDate(null);
      setCustomEndDate(null);
      setCurrentParams(updatedParams);
    } else {
      setCurrentParams({ ...currentParams, selectedTimePeriod });
    }
  };

  const handleUpdateCustomStartDate = (customStartDate) => {
    setCustomStartDate(customStartDate);
    const customStartDateISO = customStartDate.toISOString();
    setCurrentParams({ ...currentParams, startDate: customStartDateISO });
  };

  const handleUpdateCustomEndDate = (customEndDate) => {
    setCustomEndDate(customEndDate);
    const customEndDateISO = customEndDate.toISOString();
    setCurrentParams({ ...currentParams, endDate: customEndDateISO });
  };
  const handleScaleChange = (event) => {
    setCurrentParams({ ...currentParams, tempScale: event.target.value });
  };

  const handleResetSelectedSubLocations = () => {
    const updatedParams = {
      ...currentParams,
    };
    delete updatedParams.selectedSubLocations;
    setSelectedSubLocations([]);
    setCurrentParams(updatedParams);
  };

  const handleUpdateSelectedSubLocations = (selectedSubLocations: string[]) => {
    const updatedParams = {
      ...currentParams,
    };
    delete updatedParams.selectedSubLocations;
    if (selectedSubLocations.length > 0) {
      const arrString = JSON.stringify(selectedSubLocations);
      updatedParams['selectedSubLocations'] = arrString;
      setSelectedSubLocations(selectedSubLocations);
    }
    setCurrentParams(updatedParams);
  };

  const handleUpdateSelectedDevices = (selectedDevices: string[]) => {
    const updatedParams = {
      ...currentParams,
    };
    delete updatedParams.selectedDevices;
    if (selectedDevices.length > 0) {
      const arrString = JSON.stringify(selectedDevices);
      updatedParams['selectedDevices'] = arrString;
      setSelectedDevices(selectedDevices);
    }
    setCurrentParams(updatedParams);
  };

  const handleResetSelectedDevices = () => {
    const updatedParams = {
      ...currentParams,
    };
    delete updatedParams.selectedDevices;
    setSelectedDevices([]);
    setCurrentParams(updatedParams);
  };

  const showCustomRange = currentParams.selectedTimePeriod === DateRangeEnum.CUSTOM;
  const showTempRangeOptions = currentParams.selectedMetric === TEMP;
  const isDisabled =
    isLoading ||
    (currentParams.selectedTimePeriod === DateRangeEnum.CUSTOM &&
      (!customStartDate || !customEndDate));

  const toggleAdditionalFilters = () => {
    setShouldShowAdvancedFilters(!shouldShowAdvancedFilters);
  };

  const handleUpdateRefreshInterval = (event) => {
    handleSetRefreshInterval(event.target.value);
  };

  const toggleAutoRefreshCheckbox = () => {
    setShouldShowRefreshOptions((prev) => !prev);
    cancelRefetch();
    resetRefreshInteval();
  };

  const handleSearch = () => {
    const { startDate, endDate } = getCurrentDateRange(
      currentParams.selectedTimePeriod as DateRangeEnum
    );
    const updatedParams = { ...currentParams, startDate, endDate };
    if (updatedParams['alertHighlightStartDate']) {
      // clear alert info
      delete updatedParams['alertHighlightStartDate'];
    }
    if (updatedParams['alertHighlightEndDate']) {
      // clear alert info
      delete updatedParams['alertHighlightEndDate'];
    }
    setCurrentParams(updatedParams);
    setSearchParams(updatedParams);
  };

  return (
    <Card>
      <CardHeader subheader='Filter Setttings' />
      <CardContent>
        <Stack direction='column' alignItems='flex-start' spacing={2}>
          <FormControl fullWidth>
            <InputLabel id='metric'>Metric</InputLabel>
            <Select
              label='metric'
              id='metric-selector'
              value={currentParams.selectedMetric}
              onChange={handleMetricChange}
            >
              <MenuItem value={CO2}>CO2</MenuItem>
              <MenuItem value={TEMP}>Temperature</MenuItem>
              <MenuItem value={HUMIDITY}>Humidity</MenuItem>
              <MenuItem value={IAQ}>IAQ</MenuItem>
              <MenuItem value={VOC}>VOC</MenuItem>
            </Select>
          </FormControl>
          {showTempRangeOptions && (
            <FormControl>
              <RadioGroup
                row
                defaultValue={FAHRENHEIT}
                onChange={handleScaleChange}
                value={currentParams.tempScale}
              >
                <FormControlLabel
                  value={FAHRENHEIT}
                  control={<Radio sx={{ color: '#BDBDBD' }} />}
                  label='Fahrenheit'
                />
                <FormControlLabel
                  value={CELSIUS}
                  control={<Radio sx={{ color: '#BDBDBD' }} />}
                  label='Celsius'
                />
              </RadioGroup>
            </FormControl>
          )}
          <FormControl fullWidth>
            <InputLabel id='time-period'>Time Period</InputLabel>
            <Select
              label='time-period'
              id='time-period-selector'
              value={currentParams.selectedTimePeriod}
              onChange={handleTimePeriodChange}
            >
              <MenuItem value={DateRangeEnum.PAST_HOUR}>Past hour</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_SIX_HOURS}>Past 6 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_DAY}>Past 24 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_THREE_DAYS}>Past 3 days</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_WEEK}>Past week</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_MONTH}>Past month</MenuItem>
              <MenuItem value={DateRangeEnum.CUSTOM}>Custom</MenuItem>
            </Select>
          </FormControl>
          {showCustomRange && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack width='100%' gap={1}>
                <DateTimePicker
                  label='Start Date'
                  value={customStartDate}
                  onChange={handleUpdateCustomStartDate}
                  views={['year', 'day', 'hours', 'minutes']}
                />
                <DateTimePicker
                  label='End Date'
                  value={customEndDate}
                  onChange={handleUpdateCustomEndDate}
                  views={['year', 'day', 'hours', 'minutes']}
                />
              </Stack>
            </LocalizationProvider>
          )}
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='interval'>Interval</InputLabel>
            <Select
              label='interval'
              id='interval-selector'
              value={currentParams.selectedInterval}
              onChange={handleIntervalChange}
            >
              <MenuItem value='Auto' sx={{ fontStyle: 'italic' }}>
                Automatic
              </MenuItem>
              <Divider />
              {manualIntervalOptions?.map((interval) => (
                <MenuItem key={interval} value={interval}>
                  {intervalLabels[interval]}
                </MenuItem>
              ))}
            </Select>
            {currentParams.selectedInterval === 'Auto' ? (
              <FormHelperText>smart interval based on time period</FormHelperText>
            ) : null}
          </FormControl>
          <Stack width='100%' gap={1}>
            <FormControlLabel
              sx={{ ml: '-8px' }}
              label='Auto Refresh'
              control={
                <Checkbox checked={shouldShowRefreshOptions} onClick={toggleAutoRefreshCheckbox} />
              }
            />
            {shouldShowRefreshOptions && (
              <FormControl fullWidth variant='outlined'>
                <InputLabel id='refresh-interval'>Refresh Interval</InputLabel>
                <Select
                  label='refresh-interval'
                  id='refresh-interval-selector'
                  value={refreshInterval}
                  onChange={handleUpdateRefreshInterval}
                >
                  {refreshIntervals.map((interval) => (
                    <MenuItem key={interval} value={interval}>
                      {RefreshIntervalOptionsEnum[interval]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              sx={{ width: 'max-content' }}
              onClick={toggleAdditionalFilters}
              startIcon={
                shouldShowAdvancedFilters ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
              }
            >
              Advanced Filters
            </Button>
          </Stack>

          {shouldShowAdvancedFilters && (
            <Stack divider={<Divider />} width='100%'>
              <Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack direction='row' gap={1}>
                    <Typography>
                      Sub-locations
                      {selectedSubLocations?.length ? ` (${selectedSubLocations.length})` : null}
                    </Typography>
                    <Tooltip title='Compare metric data between up to 5 sub-locations'>
                      <InfoIcon color='primary' />
                    </Tooltip>
                  </Stack>
                  <IconButton
                    disabled={hasSelectedDevices}
                    onClick={() => setShouldShowSubLocationFilter((prev) => !prev)}
                  >
                    {shouldShowSubLocationFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Stack>
                {shouldShowSubLocationFilter && selectedSubLocations.length ? (
                  <Stack direction='row' justifyContent='flex-start'>
                    <Button
                      onClick={handleResetSelectedSubLocations}
                      sx={{
                        minWidth: 0,
                        padding: 0,
                      }}
                    >
                      reset
                    </Button>
                  </Stack>
                ) : null}
              </Stack>
              {shouldShowSubLocationFilter && (
                <SubLocationFilterOptionsContainer
                  isDisabled={hasSelectedDevices}
                  selectedLocationID={selectedLocationID}
                  selectedOrgID={selectedOrgID}
                  handleUpdateSelectedSubLocations={handleUpdateSelectedSubLocations}
                  selectedSubLocations={selectedSubLocations}
                />
              )}
              <Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack direction='row' gap={1}>
                    <Typography>Devices</Typography>
                    <Tooltip title='Devices that contribute to this data'>
                      <InfoIcon color='primary' />
                    </Tooltip>
                  </Stack>
                  <IconButton
                    disabled={hasSelectedSubLocations}
                    onClick={() => setShouldShowDevicesFilter((prev) => !prev)}
                  >
                    {shouldShowDevicesFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Stack>
                {shouldShowDevicesFilter && selectedDevices.length ? (
                  <Stack direction='row' justifyContent='flex-start'>
                    <Button
                      onClick={handleResetSelectedDevices}
                      sx={{
                        minWidth: 0,
                        padding: 0,
                      }}
                    >
                      reset
                    </Button>
                  </Stack>
                ) : null}
              </Stack>
              {shouldShowDevicesFilter && (
                <LocationDevicesOptionsContainer
                  isDisabled={hasSelectedSubLocations}
                  selectedLocationID={selectedLocationID}
                  selectedOrgID={selectedOrgID}
                  selectedDevices={selectedDevices}
                  updateSelectedDevices={handleUpdateSelectedDevices}
                  isReadOnly={currentUser?.currentUser?.globalRoles?.device_read ? false : true}
                />
              )}
            </Stack>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          onClick={handleSearch}
          disabled={isDisabled}
          fullWidth
          variant='contained'
          startIcon={<SearchIcon />}
        >
          search
        </Button>
      </CardActions>
    </Card>
  );
}
