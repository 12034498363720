import { gql } from '@apollo/client';
import { ORG_FRAGMENT } from 'fragments';
export const ADMIN_DEVICE_LIST = gql`
  ${ORG_FRAGMENT}
  query adminDevices(
    $deviceSearchOptions: DeviceSearchOptions
    $pagination: SkipLimitPagination
    $sort: [DeviceSearchSortInput!]
  ) {
    deviceSearch(deviceSearchOptions: $deviceSearchOptions, pagination: $pagination, sort: $sort) {
      devices {
        serialNumber
        type
        state {
          state
          timestamp
          __typename
        }
        connectivity {
          timestamp
          connected
          __typename
        }
        firmwareVersion {
          value
          timestamp
          pendingValue
          pendingTimestamp
          __typename
        }
        fullLocationPath {
          id
          name
          __typename
        }
        firstConnection
        nickname
        installationDate
        shadowReported
        account {
          ...Org
        }
        __typename
      }
      totalCount
      __typename
    }
  }
`;
