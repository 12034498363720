import { InMemoryCache, makeVar } from '@apollo/client';

import ContinuumAppPagesEnums from 'Constants/ContinuumAppPagesEnums';
import { getThemeConfig } from '../UVATheme';

import type { PaletteMode as PaletteModeType, Theme as ThemeType } from '@mui/material';
import type { ToastNotificationConfigType } from 'Providers/ToastProvider';
import type { GetCurrentUserDataQuery as UserDataType } from '__generated__/graphql';
import type { LocationItemType } from 'Hooks/useLinkedLocationList';

export const lightMode: PaletteModeType = 'light';
export const darkMode: PaletteModeType = 'dark';

const getInitTheme = (): ThemeType => {
  const localPaletteSetting = localStorage.getItem('PALETTE_MODE');
  const existingPaletteMode: PaletteModeType =
    localPaletteSetting === 'dark' ? darkMode : lightMode;
  const config = getThemeConfig(existingPaletteMode);
  return config;
};

const initTheme = getInitTheme();

export const appThemeVar = makeVar<ThemeType>(initTheme);

export const toggleAppThemeVar = (): void => {
  const currentTheme = appThemeVar();
  const currentMode = currentTheme.palette.mode;
  const newMode = currentMode === lightMode ? darkMode : lightMode;
  localStorage.setItem('PALETTE_MODE', newMode);
  const config = getThemeConfig(newMode);
  appThemeVar(config);
};

export type SelectedLocationType = {
  name: string;
  id: string;
};

export const selectedLocationVar = makeVar<LocationItemType | null>(null);

export const setSelectedLocationVar = (location: LocationItemType | null): void => {
  selectedLocationVar(location);
};

export type SelectedOrgType = NonNullable<
  NonNullable<NonNullable<UserDataType['currentUser']>['preferences']>['defaultAccount']
> | null;

export const selectedOrgVar = makeVar<SelectedOrgType>(null);
export const setSelectedOrgVar = (org: SelectedOrgType | undefined): void => {
  selectedOrgVar(org);
};

export const defaultOrgVar = makeVar<SelectedOrgType>(null);
export const setDefaultOrgVar = (org: SelectedOrgType | undefined): void => {
  defaultOrgVar(org);
};

export type CurrentUserDataType = UserDataType | undefined;

export const currentUserDataVar = makeVar<CurrentUserDataType>(undefined);

export const setCurrentUserDataVar = (userData: UserDataType | undefined): void => {
  currentUserDataVar(userData);
};

export const currentContinuumViewVar = makeVar<ContinuumAppPagesEnums>(
  ContinuumAppPagesEnums.HOME_PAGE
);

export const setCurrentContinuumViewVar = (view: ContinuumAppPagesEnums): void => {
  currentContinuumViewVar(view);
};

export const toastNotificationQueueVar = makeVar<Array<ToastNotificationConfigType>>([]);

export const addToastToQueue = (config: ToastNotificationConfigType) => {
  const updatedQ = [...toastNotificationQueueVar(), config];
  toastNotificationQueueVar(updatedQ);
};

export const removeToastFromQueue = () => {
  const updatedQ = toastNotificationQueueVar().slice(1);
  toastNotificationQueueVar(updatedQ);
};
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        alerts: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['$accountId'],

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing, incoming) {
            if (!existing) {
              return incoming; // no existing, incoming is only thing to use
            }
            // merge alert arrays
            const existingIds = new Set(existing.alerts.map((alert) => alert.__ref));
            const filteredIncomingAlerts = incoming.alerts.filter(
              (alert) => !existingIds.has(alert.__ref)
            );
            const mergedAlerts = [...existing.alerts, ...filteredIncomingAlerts];
            // return object with merged alert arrays
            return {
              ...incoming,
              alerts: mergedAlerts,
            };
          },
        },
      },
    },
    Account: {
      fields: {
        preferences: {
          merge: true,
        },
      },
    },
  },
});
