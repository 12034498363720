import { Amplify, Auth } from 'aws-amplify';
import { ApolloClient, Operation, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { cache } from './ApolloCache';
import { onError } from '@apollo/client/link/error';

import { ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { addToastToQueue } from 'Apollo/ApolloCache';

const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN_PATH}`,
      redirectSignOut: `${window.location.origin}${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT_PATH}`,
      responseType: 'code',
    },
  },
};

Amplify.configure(AMPLIFY_CONFIG);

const httpLink = createHttpLink({
  uri: (operation: Operation) => {
    return `${process.env.REACT_APP_GRAPHQL_PATH}?${operation.operationName}`;
  },
});

const authLink = setContext(async (_, { headers }) => {
  // Gets the authentication token from local storage if it exists
  const currentSession = await Auth.currentSession();
  const token = currentSession.getAccessToken().getJwtToken();
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'GraphQL Error',
        message: error.message,
      };
      addToastToQueue(notifConfig);
      console.error('GraphQL error: ', error);
    });
  }

  if (networkError) {
    // handle network error, single error
    if (!(typeof networkError === 'string' && networkError === 'No current user')) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Network Error',
        message: typeof networkError === 'string' ? networkError : networkError.message,
      };
      addToastToQueue(notifConfig);
    }
    console.error('networkError: ', networkError);
  }
});
// httpLink needs to be at the end of the array as its a terminating link.
// see: https://www.apollographql.com/docs/react/api/link/introduction
const appLink = from([errorLink, authLink, httpLink]);

export const apolloClient = new ApolloClient({
  link: appLink,
  cache,
  name: 'uva_monitoring_web',
});
