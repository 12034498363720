import { gql } from '@apollo/client';

export const ORG_FRAGMENT = gql`
  fragment Org on Account {
    id
    name
    numMembers
    numLocations
    userAccountScope {
      roles {
        account_read
        account_write
        device_read
        device_write
        location_read
        location_write
        report_read
        report_write
        users_read
        users_write
      }
    }
    deviceTypes
    rootLocation {
      id
      name
    }
    preferences {
      unit_of_measure
      logo_image_url
    }
    __typename
  }
`;
