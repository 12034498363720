import { gql, useQuery } from '@apollo/client';
import { TimeInterval as TimeIntervalEnum } from '__generated__/graphql';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
Chart.register(annotationPlugin);

import AirIcon from '@mui/icons-material/Air';
import { useTheme, Typography } from '@mui/material';
import { cache } from 'Apollo/ApolloCache';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import moment from 'moment-timezone';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';

const GET_AIR_DISINFECTED_OVER_TIME_QUERY = gql(`
  query GetAirDisinfectedOverTimeData(
    $accountId: ID!
    $locationId: ID!
    $timeFrame: TimeFrameInput!
  ) {
    report {
      uvangel {
        airTotalCubicFeetDisinfectedOverTime(accountId: $accountId, locationId: $locationId, timeFrame: $timeFrame) {
          locationReport {
            data {
              x
              y
            }
          }
        }
      }
    }
}
`);

const formatNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatDateToMonthAndDay = (isoString: string) => {
  const startPadding = 2;
  const date = new Date(isoString);
  const month = (date.getMonth() + 1).toString().padStart(startPadding, '0');
  const day = date.getDate().toString().padStart(startPadding, '0');
  return `${month}/${day}`;
};

type Props = {
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig?: GridConfigType;
};
const TITLE = 'Air Treament';
const INFO_TEXT = 'Total Air Treated in last 14 days';

export default function AirTreatmentCardContainer({
  selectedLocationID,
  selectedOrgID,
  gridConfig,
}: Props) {
  const theme = useTheme();

  // eslint-disable-next-line no-magic-numbers
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(14, 'days'), []);
  const {
    data,
    loading: isLoading,
    error: queryError,
  } = useQuery(GET_AIR_DISINFECTED_OVER_TIME_QUERY, {
    variables: {
      accountId: selectedOrgID ?? '',
      locationId: selectedLocationID ?? '',
      timeFrame: {
        startDate,
        endDate,
        timeInterval: TimeIntervalEnum.Daily,
      },
    },
  });
  const airData = data?.report?.uvangel?.airTotalCubicFeetDisinfectedOverTime?.locationReport?.data;
  const labels = airData?.map((dataPoint) => {
    const timestamp = dataPoint?.x;
    return formatDateToMonthAndDay(timestamp);
  });
  const hasData = airData && airData?.length > 0;
  let maxValue = hasData ? -Infinity : 0;
  let maxValueIndex = 0;
  let cumulativeAmount = 0;
  const airDataFormatted = airData?.map((dataPoint, index) => {
    const data = Math.round(dataPoint?.y ?? 0);
    cumulativeAmount += data;
    if (data > maxValue) {
      maxValue = data;
      maxValueIndex = index;
    }
    maxValue = Math.max(maxValue, data ?? 0);
    return data;
  });
  const backgroundColors = airDataFormatted?.map((_, index) =>
    index === maxValueIndex ? '#51ba38' : '#d7d7d7'
  );
  const chartDataFormatted = {
    labels,
    datasets: [
      {
        data: airDataFormatted,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: '#7f7f7f',
      },
    ],
  };
  const additionalText =
    cumulativeAmount > 0 ? `${formatNumberWithCommas(cumulativeAmount)} ft³` : null;

  const selectedLocation = cache.readFragment<{ id: string; name: string }>({
    id: `Location:${selectedLocationID}`,
    fragment: gql`
      fragment MyLocation on Location {
        id
        name
      }
    `,
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'days');

  return (
    <MediumMetricsCard
      additionalText={additionalText}
      isLoading={isLoading}
      icon={<AirIcon sx={{ color: queryError ? theme.palette.error.main : metricCardIconColor }} />}
      title={TITLE}
      infoText={INFO_TEXT}
      gridConfig={gridConfig}
      error={!!queryError}
    >
      {hasData ? (
        <Bar
          plugins={[annotationPlugin]}
          data={chartDataFormatted}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              bar: {
                borderRadius: 5,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                display: false,
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              annotation: {
                annotations: [
                  {
                    type: 'line',
                    borderDash: [10],
                    yMin: maxValue,
                    yMax: maxValue,
                    borderColor: '#51ba38',
                    borderWidth: 2,
                    label: {
                      position: 'end',
                      content: `${formatNumberWithCommas(maxValue)} ft³`,
                      display: true,
                      backgroundColor: 'transparent',
                      color: '#51ba38',
                      yAdjust: -12,
                    },
                  },
                ],
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => `${context.formattedValue} cubic feet`,
                },
              },
            },
          }}
        />
      ) : (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'days' : 'day'}
            </b>
          </Typography>
        </>
      )}
    </MediumMetricsCard>
  );
}
