import React, { useState } from 'react';
import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormGroup,
  FormLabel,
  CircularProgress,
  Slider,
  Chip,
  Paper,
  Divider,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OrgAlertConfigurationsSettingsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import { AlertConfiguration, AlertMetric, MutationCreateAlertConfigurationArgs } from '__generated__/graphql';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import RoutePaths from 'Constants/RoutePaths';
import { Add } from '@mui/icons-material';
import { Stack } from '@mui/system';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import { GET_ALERT_CONFIGURATIONS_QUERY } from '../OrgAlertConfigPage';
import { SelectedOrgType } from 'Apollo/ApolloCache';
import { convertTemperatureFromFahrenheit } from 'Utils/conversionTools';
import { TempScaleEnum } from 'Constants/ConversionEnums';

const { PATH_ORGANIZATION_ALERT_CONFIGS } = RoutePaths;

export const CREATE_ALERT_CONFIGURATION: TypedDocumentNode<{ createAlertConfiguration: AlertConfiguration }, MutationCreateAlertConfigurationArgs> = gql`
mutation CreateAlertConfiguration($metric: AlertMetric!, $metricThreshold: AlertThresoldInput!, $parentLocationId: ID!, $accountId: ID!, $exceedingDurationMinutes: Int!, $emailRecepients: [String!]) {
  createAlertConfiguration(metric: $metric, metricThreshold: $metricThreshold, parentLocationId: $parentLocationId, accountId: $accountId, exceedingDurationMinutes: $exceedingDurationMinutes, emailRecepients: $emailRecepients) {
    id
    account {
      id
      name
    }
    createdAt
    emailRecipients
    exceedingDurationMinutes
    metric
    metricThreshold {
      high
      low
    }
    parentLocation {
      id
      name
    }
  }
}
`;

type Props = {
  selectedOrg: SelectedOrgType;
};

type MetricOption = {
  metricName: AlertMetric,
  metricFormattedName: string,
  range: {
    min: number,
    max: number
  },
  labelFormatter: (value: number) => string;
}

const METRIC_OPTIONS: { [key: string]: MetricOption } = {
  [AlertMetric.Temp]: { // TODO: Validate whether this should be converted to C or not!?!?!?!
    metricName: AlertMetric.Temp,
    metricFormattedName: 'Temperature',
    range: {
      min: 0,
      max: 150
    },
    labelFormatter: (value: number): string => `${value} °F`
  },
  [AlertMetric.Humidity]: {
    metricName: AlertMetric.Humidity,
    metricFormattedName: 'Humidity',
    range: {
      min: 0,
      max: 100
    },
    labelFormatter: (value: number): string => `${value}%`
  },
  [AlertMetric.Co2]: {
    metricName: AlertMetric.Co2,
    metricFormattedName: 'CO₂',
    range: {
      min: 0,
      max: 5000
    },
    labelFormatter: (value: number): string => `${value} ppm`
  },
  [AlertMetric.Voc]: {
    metricName: AlertMetric.Voc,
    metricFormattedName: 'VOC',
    range: {
      min: 0,
      max: 300
    },
    labelFormatter: (value: number): string => `${value} ppm`
  },
  [AlertMetric.Iaq]: {
    metricName: AlertMetric.Iaq,
    metricFormattedName: 'IAQ',
    range: {
      min: 0,
      max: 300
    },
    labelFormatter: (value: number): string => `${value}`
  },
};

type AlertConfigurationFormData = {
  metric: AlertMetric,
  metricThreshold: {
    low?: number
    high?: number
  },
  parentLocationId: string
  exceedingDurationMinutes: number
  emailRecepients: string[]
}

const DEFAULT_EXCEEDING_DURATION_MINS_VALUE = 10;
const DEFAULT_LOW_THRESHOLD_VALUE = 25;
const DEFAULT_HIGH_THRESHOLD_VALUE = 75;
const DEFAULT_METRIC_THRESHOLD: {low?: number, high?: number} = {
  high: DEFAULT_HIGH_THRESHOLD_VALUE
};

export default function OrgAlertConfigurationCreateView({ selectedOrg }: Props) {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, setValue, getValues, resetField } = useForm<AlertConfigurationFormData>({
    defaultValues: {
      exceedingDurationMinutes: DEFAULT_EXCEEDING_DURATION_MINS_VALUE,
      metricThreshold: DEFAULT_METRIC_THRESHOLD,
      parentLocationId: selectedOrg?.rootLocation?.id || '',
    }
  });
  register('metricThreshold', {
    required: true,
    validate: (value) => {
      if (!value) {
        return false;
      }
      if (!value.high && !value.low) {
        return false;
      }
      if (value.high && value.low) {
        // if both are set, ensure high > low
        return value.high > value.low;
      }
      if (value.high || value.low) {
        // if either is set then we're good
        return true;
      }
    }
  });
  const [metricValue, setMetricValue] = React.useState<number | number[]>(DEFAULT_HIGH_THRESHOLD_VALUE);
  const [exceedingDurationValue, setExceedingDurationValue] = React.useState<number>(DEFAULT_EXCEEDING_DURATION_MINS_VALUE);
  const [emailsList, setEmailsList] = useState<Array<string>>([]);
  const [emailsInput, setEmailsInput] = useState<string>('');
  const [thresholdType, setThresholdType] = React.useState<'ranged'|'min'|'max'>('max');

  const handleMetricSliderChange = (event: Event, newValue: number | number[]) => {
    setMetricValue(newValue);
    if (typeof newValue === 'object') {
      setValue('metricThreshold', {
        low: newValue[0],
        high: newValue[1],
      });
    } else if (thresholdType === 'min') {
      setValue('metricThreshold', {
        low: newValue
      });
    } else if (thresholdType === 'max') {
      setValue('metricThreshold', {
        high: newValue
      });
    }
  };
  const handleExceedingDurationSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setExceedingDurationValue(newValue);
      setValue('exceedingDurationMinutes', newValue);
    }
  };
  const [createAlertConfiguration, { loading: isMutationInFlight }] =
    useMutation(CREATE_ALERT_CONFIGURATION);
  const { dispatchToast } = useToast();
  const onSubmit = handleSubmit(async (data) => {
    console.log('submitted data!', data);
    try {
      if (data.metric === AlertMetric.Temp) {
        // must perform conversion. data entered as F, should be sent as C.
        if (data.metricThreshold.high !== undefined) {
          data.metricThreshold.high = convertTemperatureFromFahrenheit(data.metricThreshold.high, TempScaleEnum.CELSIUS);
        }
        if (data.metricThreshold.low !== undefined) {
          data.metricThreshold.low = convertTemperatureFromFahrenheit(data.metricThreshold.low, TempScaleEnum.CELSIUS);
        }
      }
      await createAlertConfiguration({
        variables: {
          accountId: selectedOrg?.id || '',
          parentLocationId: data.parentLocationId,
          metric: data.metric,
          metricThreshold: data.metricThreshold,
          exceedingDurationMinutes: data.exceedingDurationMinutes,
          emailRecepients: data.emailRecepients,
        },
        refetchQueries: [
          GET_ALERT_CONFIGURATIONS_QUERY,
        ],
      });
      navigate(PATH_ORGANIZATION_ALERT_CONFIGS, {
        state: { panelView: OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_LIST_VIEW },
      });
    } catch (error: unknown) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Creating Alert Configuration',
        message: error ? error['message'] : 'Something went wrong. Please try again'
      };
      dispatchToast(notifConfig);
      console.error('ERROR: CREATE_ALERT_CONFIG - ', error);
    }
  });


  const handleAddEmail = (newEmail: string) => {
    if (isValidEmail(newEmail)) {
      const updatedEmailsList = [...emailsList, newEmail];
      setEmailsList(updatedEmailsList);
      setValue('emailRecepients', updatedEmailsList);
      setEmailsInput('');
    } else {
      console.warn('invalid email', emailsInput);
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Invalid Email',
        message: 'enter a valid email address',
      };
      dispatchToast(notifConfig);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail(emailsInput);
    }
  };

  const handleDelete = (targetIndex) => {
    const filteredList = emailsList.filter((tag, index) => targetIndex !== index);
    setEmailsList(filteredList);
  };

  const isValidEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return emailRegex.test(email);
  };

  const handleLocationIdChange = (locationId: string) => {
    console.log('selected locationId', locationId);
    setValue('parentLocationId', locationId);
  };

  return (
    <Box display='flex' flexDirection='column' width={600}>
      <form onSubmit={onSubmit}>
        <Box display='flex' flexDirection='column' gap={0.5} marginBottom={2}>
          <FormLabel htmlFor='metricInput'>Metric</FormLabel>
          <Select
            {...register('metric', {
              required: true,
              onChange: () => {
                // reset a lot of this form so we dont get desynced
                setThresholdType('max'); // clear threshold type since metric changed
                setMetricValue(DEFAULT_HIGH_THRESHOLD_VALUE);
                resetField('metricThreshold');
              }
            })}
            size='small'
            sx={{ width: 200 }}
          >
            <MenuItem value={undefined}>Select a Metric</MenuItem>
            {Object.values(METRIC_OPTIONS).map((value, index) => (
              <MenuItem key={index} value={value.metricName}>{value.metricFormattedName}</MenuItem>
            ))}
          </Select>
        </Box>
        {getValues('metric') ? 
          <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
            <FormLabel htmlFor='keyNameInput'>Metric Value Range</FormLabel>
            <Select
              value={thresholdType}
              onChange={(e) => {
                if (!['ranged', 'min', 'max'].includes(e.target.value)) {
                  console.error('invalid range mode selected - should not happen');
                  return;
                }
                setThresholdType(e.target.value as ('ranged' | 'min' | 'max'));
                if (e.target.value === 'ranged') {
                  setMetricValue([DEFAULT_LOW_THRESHOLD_VALUE, DEFAULT_HIGH_THRESHOLD_VALUE]);
                  setValue('metricThreshold', {
                    low: DEFAULT_LOW_THRESHOLD_VALUE,
                    high: DEFAULT_HIGH_THRESHOLD_VALUE
                  });
                } else if (e.target.value === 'min') {
                  setMetricValue(DEFAULT_LOW_THRESHOLD_VALUE);
                  setValue('metricThreshold', {
                    low: DEFAULT_LOW_THRESHOLD_VALUE,
                  });
                } else if (e.target.value === 'max') {
                  setMetricValue(DEFAULT_HIGH_THRESHOLD_VALUE);
                  setValue('metricThreshold', {
                    high: DEFAULT_HIGH_THRESHOLD_VALUE,
                  });
                }
              }}
              size='small'
              sx={{ width: 200 }}
            >
              <MenuItem value='max'>Max Only</MenuItem>
              <MenuItem value='min'>Min Only</MenuItem>
              <MenuItem value='ranged'>Min / Max Range</MenuItem>
            </Select>
            {thresholdType && thresholdType.length > 0 ?
              <Stack gap={1}>
                <Slider
                  track={thresholdType === 'min' ? 'inverted' : undefined}
                  {...METRIC_OPTIONS[getValues('metric')].range}
                  value={metricValue}
                  onChange={handleMetricSliderChange}
                  valueLabelDisplay='on'
                  sx={{
                    marginTop: 5
                  }}
                  valueLabelFormat={(value) => METRIC_OPTIONS[getValues('metric')].labelFormatter(value) }
                />
              </Stack> : null
            }
          </Box> : null
        }
        {getValues('metric') && (getValues('metricThreshold').high || getValues('metricThreshold').low) ? 
          <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
            <FormLabel htmlFor='keyNameInput'>Minimum Duration</FormLabel>
            <Slider
              min={1}
              max={60}
              value={exceedingDurationValue}
              onChange={handleExceedingDurationSliderChange}
              valueLabelDisplay='on'
              sx={{
                marginTop: 5
              }}
              valueLabelFormat={(value) => value > 1 ? `${value} minutes` : `${value} minute`}
            />
          </Box> : null
        }
        {getValues('metric') && (getValues('metricThreshold').high || getValues('metricThreshold').low) ? 
          <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
            <FormLabel htmlFor='keyNameInput'>Email Recepients</FormLabel>
            <Stack gap={2}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                {emailsList.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleDelete(index)}
                    sx={{ marginTop: 1, marginLeft: 0.5 }}
                  />
                ))}
              </Box>
              <Paper
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
              >
                <InputBase
                  fullWidth
                  placeholder='Enter email'
                  type='email'
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setEmailsInput(e.target.value)}
                  value={emailsInput}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => {
                  handleAddEmail(emailsInput);
                }}>
                  <Add />
                </IconButton>
              </Paper>
            </Stack>
          </Box> : null
        }
        {getValues('metric') && (getValues('metricThreshold').high || getValues('metricThreshold').low) && getValues('exceedingDurationMinutes') ? 
          <Box display='flex' flexDirection='column' alignItems={'center'} gap={0.5} width={425} marginBottom={2}>
            <Typography>
              You will be alerted when <b>{METRIC_OPTIONS[getValues('metric')].metricFormattedName}</b> in the following location
            </Typography>
            <LocationSelectorBreadcrumb
              onLocationSelectCallback={handleLocationIdChange}
            />
            {thresholdType === 'ranged' ?
              <Typography>
                <b>falls below {METRIC_OPTIONS[getValues('metric')].labelFormatter(getValues('metricThreshold.low') || 0)}</b> or <b>rises above {METRIC_OPTIONS[getValues('metric')].labelFormatter(getValues('metricThreshold.high') || 0)}</b> for at least <b>{exceedingDurationValue} minutes</b>.
              </Typography>: null
            }
            {thresholdType === 'min' ?
              <Typography>
                <b>falls below {METRIC_OPTIONS[getValues('metric')].labelFormatter(getValues('metricThreshold.low') || 0)}</b> for at least <b>{exceedingDurationValue} minutes</b>.
              </Typography>: null
            }
            {thresholdType === 'max' ?
              <Typography>
                <b>rises above {METRIC_OPTIONS[getValues('metric')].labelFormatter(getValues('metricThreshold.high') || 0)}</b> for at least <b>{exceedingDurationValue} minutes</b>.
              </Typography>: null
            }
          </Box> : null
        }

        <FormGroup row>
          <Button
            disabled={isMutationInFlight}
            color='error'
            sx={{
              height: 50,
              width: 200,
              marginRight: 2,
            }}
            onClick={() =>
              navigate(PATH_ORGANIZATION_ALERT_CONFIGS, {
                state: { panelView: OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_LIST_VIEW },
              })
            }
            variant='contained'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={!formState.isValid || isMutationInFlight}
            color='primary'
            sx={{ height: 50, width: 200 }}
            variant='contained'
            startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
          >
            Create Alert Configuration
          </Button>
        </FormGroup>
      </form>
    </Box>
  );
}
