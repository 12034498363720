/* eslint-disable no-magic-numbers */
import { useReactiveVar } from '@apollo/client';
import { Replay as ResetIcon, Search } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BinInterval, Maybe, OccupancyReportQueryVariables } from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { hoursMap } from 'Components/AuthedPages/InfectionPage/PageViews/InfectionFilters';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { BIN_INTERVAL_LABELS } from 'Utils/manageIntervals';

interface OccupancyFiltersProps {
  locationId?: Maybe<string>;
  filterHandler: (args: OccupancyReportQueryVariables) => void;
  timeInterval?: Maybe<BinInterval>;
}

const EXPERIMENTAL_OPTIONS_DEFAULT = {
  perPersonCo2EmissionRateLitersPerHourOverride: 14.222,
  ventilationRateAchOverride: 9,
  baselineCo2PpmOverride: 425,
};
export default function OccupancyFilters({
  locationId,
  timeInterval,
  filterHandler,
}: OccupancyFiltersProps) {
  const [params, setSearchParams] = useSearchParams();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const startDate = params.get('start-date') as string;
  const endDate = params.get('end-date') as string;

  const [selectedTimeInterval, setSelectedTimeInterval] = useState<BinInterval>(
    (params.get('interval') as BinInterval) ?? timeInterval ?? BinInterval.FiveMinutes
  );

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    (params.get('period') as DateRangeEnum) ?? DateRangeEnum.PAST_SIX_HOURS
  );
  const getDateRangeFromCurrent = useCallback((selectedTimePeriod: DateRangeEnum) => {
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      selectedTimePeriod = DateRangeEnum.PAST_DAY;
    }
    return getPastDateTimeRange(hoursMap[selectedTimePeriod], 'hours');
  }, []);
  const [customDates, setCustomDates] = useState<
    [moment.Moment | null | undefined, moment.Moment | null | undefined]
  >(() => {
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return [moment(startDate), moment(endDate)];
    }
    return [void 0, void 0];
  });
  const [advanceFiltersEnabled, setAdvanceFiltersEnabled] = useState(false);
  const [advanceFiltersData, setAdvanceFiltersData] = useState(EXPERIMENTAL_OPTIONS_DEFAULT);

  const applyFilters = useCallback(() => {
    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = getDateRangeFromCurrent(selectedTimePeriod);
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      startDate = customDates[0]?.toISOString() ?? '';
      endDate = customDates[1]?.toISOString() ?? '';
    }
    setSearchParams(
      (params) => {
        const prev = Object.fromEntries(params.entries());
        prev['location'] = locationId ?? '';
        prev['period'] = selectedTimePeriod;
        prev['start-date'] = startDate;
        prev['end-date'] = endDate;
        prev['interval'] = selectedTimeInterval;
        return prev;
      },
      { replace: true }
    );
    const filterArgs: OccupancyReportQueryVariables = {
      input: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        timeRange: {
          startDate: startDate,
          endDate: endDate,
        },
        co2BinPeriod: selectedTimeInterval,
      },
    };
    if (advanceFiltersEnabled) {
      filterArgs.input = {
        ...filterArgs.input,
        ...advanceFiltersData,
      };
    }
    filterHandler(filterArgs);
  }, [
    selectedTimePeriod,
    locationId,
    selectedOrg,
    customDates,
    filterHandler,
    getDateRangeFromCurrent,
    setSearchParams,
    advanceFiltersData,
    advanceFiltersEnabled,
    selectedTimeInterval,
  ]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader subheader='Filters' />
      <CardContent>
        <Stack width='100%' gap={2}>
          <FormControl fullWidth>
            <InputLabel id='time-period'>Time Period</InputLabel>
            <Select
              label='time-period'
              id='time-period-selector'
              value={selectedTimePeriod}
              onChange={(e) => setSelectedTimePeriod(e.target.value as DateRangeEnum)}
            >
              <MenuItem value={DateRangeEnum.PAST_HOUR}>Past hour</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_SIX_HOURS}>Past 6 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_DAY}>Past 24 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_THREE_DAYS}>Past 3 days</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_WEEK}>Past week</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_MONTH}>Past month</MenuItem>
              <MenuItem value={DateRangeEnum.CUSTOM}>Custom</MenuItem>
            </Select>
          </FormControl>
          {selectedTimePeriod === DateRangeEnum.CUSTOM && (
            <Fragment>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label='Start Date'
                  value={customDates[0]}
                  maxDateTime={customDates[1]}
                  onChange={(date) => setCustomDates((prev) => [date as moment.Moment, prev[1]])}
                  views={['year', 'day', 'hours', 'minutes']}
                />
                <DateTimePicker
                  label='End Date'
                  value={customDates[1]}
                  minDateTime={customDates[0]}
                  onChange={(date) => setCustomDates((prev) => [prev[0], date as moment.Moment])}
                  views={['year', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
            </Fragment>
          )}
          <FormControl fullWidth>
            <InputLabel id='time-interval'>Bin Interval</InputLabel>
            <Select
              label='time-interval'
              id='time-interval-selector'
              value={selectedTimeInterval}
              onChange={(e) => setSelectedTimeInterval(e.target.value as BinInterval)}
            >
              {[
                BinInterval.OneMinute,
                BinInterval.FiveMinutes,
                BinInterval.TenMinutes,
                BinInterval.FifteenMinutes,
                BinInterval.ThirtyMinutes,
                BinInterval.OneHour,
              ].map((interval) => (
                <MenuItem key={interval} value={interval}>
                  {BIN_INTERVAL_LABELS[interval]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack width='100%' direction='row' justifyContent='space-between'>
            <FormControlLabel
              control={
                <Checkbox
                  value={advanceFiltersEnabled}
                  onChange={(e) => setAdvanceFiltersEnabled(e.target.checked)}
                />
              }
              label='Experimental Options'
            />
            {advanceFiltersEnabled && (
              <Tooltip title='Reset to defaults'>
                <IconButton onClick={() => setAdvanceFiltersData(EXPERIMENTAL_OPTIONS_DEFAULT)}>
                  <ResetIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          {advanceFiltersEnabled && (
            <Fragment>
              <TextField
                label='CO2 Emission override'
                type='number'
                name='perPersonCo2EmissionRateLitersPerHourOverride'
                value={advanceFiltersData.perPersonCo2EmissionRateLitersPerHourOverride}
                onChange={(e) =>
                  setAdvanceFiltersData((prev) => ({
                    ...prev,
                    [e.target.name]: parseFloat(e.target.value),
                  }))
                }
                helperText='Per Person CO2 Emission Rate l/hr override'
              />
              <TextField
                label='Ventilation Rate Override'
                type='number'
                name='ventilationRateAchOverride'
                value={advanceFiltersData.ventilationRateAchOverride}
                onChange={(e) =>
                  setAdvanceFiltersData((prev) => ({
                    ...prev,
                    [e.target.name]: parseFloat(e.target.value),
                  }))
                }
              />
              <TextField
                label='Baseline CO2 Override'
                type='number'
                name='baselineCo2PpmOverride'
                value={advanceFiltersData.baselineCo2PpmOverride}
                onChange={(e) =>
                  setAdvanceFiltersData((prev) => ({
                    ...prev,
                    [e.target.name]: parseFloat(e.target.value),
                  }))
                }
              />
            </Fragment>
          )}
        </Stack>
        <CardActions sx={{ mt: 2, px: 0 }}>
          <Button fullWidth variant='contained' startIcon={<Search />} onClick={applyFilters}>
            Apply Filters
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
