import { gql } from '@apollo/client';
import { ORG_FRAGMENT } from 'fragments';
export const GET_AVAILABLE_ACCOUNTS_QUERY = gql`
  ${ORG_FRAGMENT}
  query GetAvailableAccountsData($pagination: PaginatedInput, $searchString: String) {
    accounts(pagination: $pagination, searchString: $searchString) {
      totalCount
      nextToken
      accounts {
        ...Org
      }
    }
  }
`;

export const UPDATE_USER_ORG = gql`
  mutation updateUserPreferences($preferences: UserPreferencesInput) {
    updateUserPreferences(preferences: $preferences) {
      id
      preferences {
        defaultAccountId
        __typename
      }
      __typename
    }
  }
`;

export const ADMIN_REGISTER_DEVICE_TO_ORG = gql`
  mutation RegisterDeviceToOrg($accountId: ID!, $locationId: ID!, $deviceIdentifiers: [String]!) {
    registerDevices(
      accountId: $accountId
      locationId: $locationId
      deviceIdentifiers: $deviceIdentifiers
    ) {
      numUpdated
      numCreated
      numFailed
      failedDeviceSerials
    }
  }
`;
