/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Stack,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NetworkStatus, gql, useLazyQuery, useReactiveVar } from '@apollo/client';

import { useSignOut } from 'Hooks/useSignOut';
import UserAvatar from './SharedUI/UserAvatar';
import PaletteModeCard from './NavBar/Cards/PaletteModeCard';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import RoutePaths from 'Constants/RoutePaths';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import {
  ExitToApp as ExitToAppIcon,
  SettingsBrightness as SettingsBrightnessIcon,
  ManageAccounts as ManageAccountsIcon,
  Loop as LoopIcon,
  Menu as MenuIcon,
  Addchart as AddchartIcon,
} from '@mui/icons-material';
const { SWITCH_ORG_MODAL } = ModalNotificationTypeEnum;
import type { CurrentUserDataType } from 'Apollo/ApolloCache';
import AlertsDropdown from './NavBar/Cards/AlertsDropdown';

const { PATH_USER_SETTINGS } = RoutePaths;

export const GET_ACCOUNT_ALERTS = gql`
  query Alerts($accountId: ID!, $pageSize: Int, $page: Int) {
    alerts(accountId: $accountId, pageSize: $pageSize, page: $page) {
      totalCount
      totalUnread
      alerts {
        id
        isUnread
        metric
        metricValue
        startTime
        endTime
        location {
          id
          name
        }
        alertConfiguration {
          id
          metricThreshold {
            high
            low
          }
          exceedingDurationMinutes
        }
      }
    }
  }
`;

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  userData: CurrentUserDataType;
  handleToggleSideBar: () => void;
};

export default function DesktopHeaderBar({
  firstName,
  lastName,
  userData,
  email,
  handleToggleSideBar,
}: Props) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [userSettingsAnchorElement, setUserSettingsAnchorElement] = useState<null | HTMLElement>(
    null
  );
  const [currentAlertsPage, setCurrentAlertsPage] = useState<number>(1);
  const handleSignOut = useSignOut();
  const isUserSettingsOpen = Boolean(userSettingsAnchorElement);
  const isLoading = Boolean(userData == null);
  const handleUserSettingsMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (userSettingsAnchorElement) {
      setUserSettingsAnchorElement(null);
    } else {
      setUserSettingsAnchorElement(event.currentTarget);
    }
  };
  const { dispatchModal } = useModal();

  const [
    getAlerts,
    {
      data: alertsData,
      loading: alertsLoading,
      error: queryError,
      fetchMore: fetchMoreAlerts,
      networkStatus: alertsNetworkStatus,
    },
  ] = useLazyQuery(GET_ACCOUNT_ALERTS, {
    variables: {
      accountId: selectedOrg?.id || '',
      pageSize: 20,
      page: currentAlertsPage,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (selectedOrg && selectedOrg.id) {
      getAlerts();
    }
  }, [selectedOrg, getAlerts]);

  useEffect(() => {
    if (currentAlertsPage <= 1) {
      return;
    }
    fetchMoreAlerts({
      variables: {
        page: currentAlertsPage,
      },
    });
  }, [currentAlertsPage, fetchMoreAlerts]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      alignSelf='flex-end'
      bgcolor={isMobile ? theme.palette.primary.main : 'inherit'}
      direction='row'
      justifyContent={'space-between'}
      alignItems='center'
      width={isMobile ? '100%' : 'calc(100% - 240px)'}
      height={70}
      padding={2}
      marginBottom={2}
    >
      {isMobile && selectedOrg && (
        <Box marginRight={'auto'}>
          <Button
            onClick={handleToggleSideBar}
            disabled={isLoading}
            sx={{ borderRadius: 10, minWidth: 40 }}
            color='inherit'
          >
            <MenuIcon />
          </Button>
        </Box>
      )}
      {selectedOrg && (
        <Typography justifyContent={{ marginRight: 'auto' }}>{selectedOrg.name}</Typography>
      )}
      {userData ? (
        <AlertsDropdown
          alertsData={alertsData}
          isLoading={alertsLoading || !alertsData}
          isFetchingMore={alertsNetworkStatus === NetworkStatus.fetchMore}
          queryError={queryError}
          fetchMoreAlerts={() => {
            setCurrentAlertsPage(currentAlertsPage + 1);
          }}
        />
      ) : null}
      <Button
        aria-controls='menu-appbar'
        aria-haspopup='true'
        aria-label='User settings menu'
        color='inherit'
        disabled={isLoading}
        onClick={handleUserSettingsMenuClick}
        size='large'
        sx={{ borderRadius: 10, minHeight: 50, minWidth: 50, padding: 0 }}
      >
        {userData ? (
          <UserAvatar
            firstName={firstName ?? ''}
            lastName={lastName ?? ''}
            height={35}
            width={35}
            fontSize={14}
          />
        ) : (
          <CircularProgress color='inherit' size={20} />
        )}
      </Button>
      <Menu
        anchorEl={userSettingsAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isUserSettingsOpen}
        onClick={handleUserSettingsMenuClick}
      >
        <MenuItem
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
            marginBottom: 1,
          }}
        >
          <Stack direction='row' gap={2} alignItems='center'>
            {userData ? (
              <UserAvatar
                firstName={firstName ?? ''}
                lastName={lastName ?? ''}
                height={35}
                width={35}
                fontSize={14}
              />
            ) : (
              <CircularProgress color='inherit' size={20} />
            )}
            <Stack>
              <Typography fontWeight='bold'>{firstName + ' ' + lastName}</Typography>
              <Typography lineHeight={0.75} fontSize={14}>
                {email}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() =>
            dispatchModal({
              type: SWITCH_ORG_MODAL,
              modalProps: {
                origin: 'switch-session-org',
              },
            })
          }
        >
          <ListItemIcon>
            <LoopIcon />
          </ListItemIcon>
          <ListItemText primary='Switch organization' />
        </MenuItem>
        <Divider />
        <MenuItem
          disableRipple
          onClick={(e) => {
            const target = e.target as HTMLInputElement;
            if (target.id !== 'palleteModeButton') {
              e.stopPropagation();
            }
          }}
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <ListItemIcon>
            <SettingsBrightnessIcon />
          </ListItemIcon>
          <PaletteModeCard />
        </MenuItem>
        <MenuItem component={RouterLink} to={PATH_USER_SETTINGS}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText primary='User settings' />
        </MenuItem>
        {userData?.currentUser?.globalRoles?.account_create && (
          <MenuItem
            onClick={() => dispatchModal({ type: ModalNotificationTypeEnum.CREATE_ORGANIZATION })}
          >
            <ListItemIcon>
              <AddchartIcon />
            </ListItemIcon>
            <ListItemText primary='Create New Organization' />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='Log out' />
        </MenuItem>
      </Menu>
    </Stack>
  );
}
