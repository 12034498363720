/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { gql, useLazyQuery } from '@apollo/client';
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Grid, Stack } from '@mui/material';
import {
  BinInterval,
  OccupancyReportQuery,
  OccupancyReportQueryVariables,
} from '__generated__/graphql';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import OccupancyFilters from './PageViews/OccupancyFilters';
import OccupancyView from './PageViews/OccupancyView';

const OCCUPANCY_REPORT = gql`
  query OccupancyReport($input: LocationOccupancyReportInput!) {
    report {
      uvangel {
        getLocationOccupancyForDuration(input: $input) {
          timestamp
          co2Ppm
          co2Emission
          peopleUnits
          roundedOccupancy
        }
      }
    }
  }
`;
const ERROR_MESSAGE = [
  'Location must be "archetype: ROOM" with "airVolumeFt3" metadata configured',
  'Response not successful: Received status code 400',
];
interface OccupancyPageProps {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
}

export default function OccupancyPage({ selectedOrg, selectedLocation }: OccupancyPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [cycleStartDate, cycleEndDate] = useMemo(() => {
    const startDateFromParams = searchParams.get('start-date') as string | undefined;
    const endDateFromParams = searchParams.get('end-date') as string | undefined;

    if (startDateFromParams && endDateFromParams) {
      return [startDateFromParams, endDateFromParams];
    }
    return getPastDateTimeRange(6, 'hours');
  }, [searchParams]);
  const [isLocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);

  const currentInterval: BinInterval = useMemo(() => {
    const intervalFromParams = searchParams.get('interval') as BinInterval | undefined;
    if (!intervalFromParams) {
      return BinInterval.FiveMinutes;
    }
    return intervalFromParams;
  }, [searchParams]);
  const [
    refetch,
    {
      data: occupancyReport,
      loading: occupancyReportLoading,
      variables: occupancyReportVars,
      error,
    },
  ] = useLazyQuery<OccupancyReportQuery, OccupancyReportQueryVariables>(OCCUPANCY_REPORT, {
    variables: {
      input: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        timeRange: {
          endDate: cycleEndDate,
          startDate: cycleStartDate,
        },
        co2BinPeriod: currentInterval,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const filterHandler = useCallback(
    (args: OccupancyReportQueryVariables) => {
      refetch({ variables: args });
    },
    [refetch]
  );

  return (
    <PageContainerFrame pageTitles={['Occupancy']}>
      <SettingsPanelFrame
        title='Occupancy Analysis'
        description={
          <Box component='span' display='flex' alignItems='center'>
            <Chip label='Beta' variant='outlined' icon={<AutoAwesomeIcon fontSize='inherit' />} />
          </Box>
        }
      >
        <Box alignSelf='center'>
          {!isLocationSelectorLoading && (
            <LocationSelectorBreadcrumb
              preLoadedLocationList={initLocationList}
              onLocationSelectCallback={(id) => {
                setLocationId(id);
                setSearchParams(
                  (prev) => ({ ...Object.fromEntries(prev.entries()), location: id }),
                  {
                    replace: true,
                  }
                );
                filterHandler(
                  _.merge(occupancyReportVars, { input: { locationId: locationId ?? '' } })
                );
              }}
            />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            {occupancyReportLoading ? (
              <CircularProgress />
            ) : (
              <OccupancyView
                report={occupancyReport?.report?.uvangel?.getLocationOccupancyForDuration}
                selectedLocation={selectedLocation}
                invalidLocation={ERROR_MESSAGE.map((t) => t.toLocaleLowerCase()).includes(
                  error?.message.toLocaleLowerCase() ?? ''
                )}
                selectedInterval={currentInterval}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Stack width='100%' gap={2}>
              <OccupancyFilters
                locationId={locationId}
                filterHandler={filterHandler}
                timeInterval={currentInterval}
              />
            </Stack>
          </Grid>
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
