import { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './Utils/chartConfig';
import '@aws-amplify/ui-react/styles.css';
import { RequireAuth } from './Components/HOC/RequireAuth';
import { CssBaseline } from '@mui/material';
import { useQuery, useLazyQuery, gql, useReactiveVar } from '@apollo/client';
import { useIdleTimer } from 'react-idle-timer';
import { useErrorBoundary } from 'react-error-boundary';

import {
  setDefaultOrgVar,
  setSelectedOrgVar,
  setCurrentUserDataVar,
  setSelectedLocationVar,
  selectedLocationVar,
} from 'Apollo/ApolloCache';
import { useLocation, useParams } from 'react-router-dom';
import { useSignOut } from 'Hooks/useSignOut';
import { continuumAppPathToPageMap } from 'Constants/ContinuumAppPagesEnums';
import ContinuumAppPageEnums from 'Constants/ContinuumAppPagesEnums';
import ContinuumCurrentPageRenderer from 'Components/ContinuumCurrentPageRenderer';
import SideBar from 'Components/Sidebar/SideBar';
import ContinuumHeaderContainer from 'Components/ContinuumHeaderContainer';
import RouteStatusEnum from 'Constants/RouteStatusEnum';
import ErrorBoundary from 'Components/SharedUI/ErrorBoundary';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import {
  UserAgreementType,
  type GetCurrentUserDataQuery as UserDataType,
} from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import { ORG_FRAGMENT } from 'fragments';

const { STATUS_UNAUTHENTICATED } = RouteStatusEnum;

export const GET_CURRENT_USER_DATA_QUERY = gql`
  ${ORG_FRAGMENT}
  query GetCurrentUserData {
    currentUser {
      id
      firstName
      lastName
      email
      memberAccounts {
        ...Org
      }
      preferences {
        defaultAccount {
          ...Org
        }
      }
      globalRoles {
        account_create
        account_remove
        account_read
        account_write
        users_read
        users_write
        location_read
        location_write
        report_read
        report_write
        device_read
        device_write
      }
      agreements {
        agreedAt
        type
        version
      }
    }
  }
`;

export const GET_ORG_ACCOUNT_DATA_QUERY = gql`
  ${ORG_FRAGMENT}
  query GetOrgAccountData($accountId: ID!) {
    account(accountId: $accountId) {
      ...Org
    }
  }
`;

export const sideBarWidth = 240;

const MAX_IDLE_USER_TIME_LIMIT = Number(process.env.REACT_APP_MAX_IDLE_USER_TIME_LIMIT);

const getSelectedOrg = async (
  data: UserDataType | undefined,
  fetchCallback
): Promise<SelectedOrgType | undefined> => {
  const selectedOrgID = localStorage.getItem('SELECTED_ORGANIZATION_ID');
  if (selectedOrgID === null) {
    const selectedOrg = data?.currentUser?.preferences?.defaultAccount;
    localStorage.setItem('SELECTED_ORGANIZATION_ID', selectedOrg?.id ?? '');
    return selectedOrg;
  } else {
    const orgFoundInMemberAccounts = data?.currentUser?.memberAccounts?.find(
      (org) => org?.id === selectedOrgID
    );
    // If org data is not found amongst the users member accounts we make a separate query to find the org.
    if (orgFoundInMemberAccounts) {
      return orgFoundInMemberAccounts;
    } else {
      const res = await fetchCallback({
        variables: {
          accountId: selectedOrgID,
        },
      });
      const org = res.data?.account;
      return org;
    }
  }
};

export default function ContinuumApp() {
  const { dispatchModal } = useModal();
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const {
    data: currentUserData,
    loading: isUserDataLoading,
    error: userQueryError,
    refetch: refetchUser,
  } = useQuery(GET_CURRENT_USER_DATA_QUERY);
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const [getOrgAccountData, { loading: isOrgQueryLoading, error: orgQueryError }] = useLazyQuery(
    GET_ORG_ACCOUNT_DATA_QUERY
  );
  if (userQueryError) {
    showErrorBoundary(userQueryError);
  }
  if (orgQueryError) {
    showErrorBoundary(orgQueryError);
  }

  const isLoading = isUserDataLoading || isOrgQueryLoading;

  const { authStatus } = useAuthenticator((context) => [context.route]);
  const [timeout, setTimeout] = useState<number>(MAX_IDLE_USER_TIME_LIMIT);

  const handleSignOut = useSignOut();

  const onIdle = () => {
    handleSignOut();
  };
  const onAction = () => {
    setTimeout(MAX_IDLE_USER_TIME_LIMIT);
  };

  useIdleTimer({ onAction, onIdle, timeout });

  useEffect(() => {
    if (authStatus === STATUS_UNAUTHENTICATED) {
      handleSignOut();
    }
  }, [authStatus, handleSignOut]);

  useEffect(() => {
    const getAndSetInitAppVariables = async () => {
      const selectedOrg = await getSelectedOrg(currentUserData, getOrgAccountData);
      if (selectedOrg) {
        setSelectedOrgVar(selectedOrg);
      }
      setDefaultOrgVar(currentUserData?.currentUser?.preferences?.defaultAccount);
      setCurrentUserDataVar(currentUserData);
      const rootLocationID = selectedOrg?.rootLocation?.id ?? '';
      const rootLocationName = selectedOrg?.rootLocation?.name ?? 'All Locations';

      if (!selectedLocation) {
        setSelectedLocationVar({ name: rootLocationName, id: rootLocationID });
      }

      const userInfo = {
        firstName: currentUserData?.currentUser?.firstName,
        lastName: currentUserData?.currentUser?.lastName,
      };
      localStorage.setItem('CURRENT_USER_INFO', JSON.stringify(userInfo));
    };
    if (!isUserDataLoading && authStatus !== STATUS_UNAUTHENTICATED) {
      getAndSetInitAppVariables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData, isUserDataLoading, getOrgAccountData, dispatchModal, authStatus]);

  useEffect(() => {
    if (
      currentUserData?.currentUser &&
      ![UserAgreementType.PortalAccessAgreement, UserAgreementType.PrivacyPolicy].every((t) =>
        currentUserData.currentUser?.agreements?.some((ag) => ag.type === t)
      )
    ) {
      dispatchModal({
        type: ModalNotificationTypeEnum.USER_AGREEMENT,
        modalProps: {
          agreements: currentUserData.currentUser?.agreements,
          onSuccess: () => refetchUser,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  const location = useLocation();
  const params = useParams();
  const currentPathName = useMemo(() => {
    let path = location.pathname;
    Object.entries(params).forEach(([k, v]) => {
      if (!v) {
        return;
      }
      path = path.replace(v, `:${k}`);
    });
    return path;
  }, [location, params]);

  const currentPage = continuumAppPathToPageMap[currentPathName] ?? ContinuumAppPageEnums.HOME_PAGE;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const sideBarWidthOffset = isMobileView ? 0 : sideBarWidth;

  const [isSideBarOpen, setIsSidebarOpen] = useState(!isMobileView);

  const handleToggleSideBar = useCallback(() => {
    // currently we only support sidebar collapse in mobile view
    if (isMobileView) {
      setIsSidebarOpen((prevOpen) => !prevOpen);
    }
  }, [isMobileView, setIsSidebarOpen]);

  useEffect(() => {
    // collapses sidebar on switch to mobile view
    if (isMobileView) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [isMobileView, setIsSidebarOpen]);

  return (
    <ErrorBoundary>
      <RequireAuth>
        <Box display='flex' flexDirection='column'>
          <CssBaseline />
          <SideBar
            currentPage={currentPage}
            width={sideBarWidth}
            handleToggleSideBar={handleToggleSideBar}
            isSideBarOpen={isSideBarOpen}
            isMobileView={isMobileView}
          />
          <ContinuumHeaderContainer handleToggleSideBar={handleToggleSideBar} />
          {/* this error boundary is specifically to catch errors in the view section */}
          <ErrorBoundary isMobileView={isMobileView}>
            <Box
              display='flex'
              flexDirection='column'
              left={sideBarWidthOffset}
              position='absolute'
              top={70}
              height='100%'
              width={`calc(100% - ${sideBarWidthOffset}px)`}
            >
              {isLoading ? (
                <CircularProgress size={100} sx={{ alignSelf: 'center', marginTop: 20 }} />
              ) : (
                <ContinuumCurrentPageRenderer
                  currentPage={currentPage}
                  isUserDataLoading={isUserDataLoading}
                />
              )}
            </Box>
          </ErrorBoundary>
        </Box>
      </RequireAuth>
    </ErrorBoundary>
  );
}
