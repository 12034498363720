import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';

type Props = {
  children: ReactNode;
  pageTitles: (string|null|undefined)[]
};

// this HOC is used provide consistent css framing of views panels
export default function PageContainerFrame({ children, pageTitles }: Props) {
  const pageTitle = formatPageTitle(pageTitles);
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={2}
      width='100%'
      paddingTop={2}
      paddingBottom={2}
    >
      <Box display='flex' flexDirection='column' alignItems='center' width='100%' maxWidth={2250}>
        <Helmet defer={false}>
          <title>{pageTitle}</title>
        </Helmet>
        {children}
      </Box>
    </Box>
  );
}
