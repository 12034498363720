import { Check as CheckIcon, ContentCopy as CopyIcon } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { AdminDevicesQuery } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import useCopy from 'Hooks/useCopy';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import DeviceConnectivityCell from '../../DevicesListPage/PageViews/DeviceConnectivityCell';
import DeviceFirmwareCell from '../../DevicesListPage/PageViews/DeviceFirmwareCell';
import DeviceIconCell from '../../DevicesListPage/PageViews/DeviceIconCell';
import DeviceLocationCell from '../../DevicesListPage/PageViews/DeviceLocationCell';
import DeviceStateCell from '../../DevicesListPage/PageViews/DeviceStateCell';
import { DeviceOrgCell } from '../common';
import useSwitchOrg from 'Hooks/useSwitchOrg';

type DevicesListProps = {
  devices: NonNullable<AdminDevicesQuery['deviceSearch']>['devices'];
  totalCount: number;
  selectedOrg?: SelectedOrgType;
  loadNextPage?: () => void;
  postRegisterDevice: (
    serialNumber: string,
    org: Record<'id' | 'name', string>,
    location: Record<'id' | 'name', string>
  ) => void;
};

export default function DevicesListMobileView({
  devices,
  totalCount,
  loadNextPage,
  postRegisterDevice,
}: DevicesListProps) {
  const switchOrg = useSwitchOrg();
  const { dispatchToast } = useToast();
  const [copied, copyText] = useCopy();
  if (!devices || devices.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h5'>No devices Found</Typography>
        <Typography variant='body1' color='gray'>
          Update the filters or add devices
        </Typography>
      </Paper>
    );
  }
  return (
    <Fragment>
      <Box width='100%' my={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          {totalCount} devices that match your search criteria
        </Typography>
      </Box>
      <InfiniteScroll
        dataLength={devices.length}
        next={() => (loadNextPage ? loadNextPage() : void 0)}
        hasMore={devices.length < totalCount}
        loader={<LinearProgress />}
      >
        <Box display='flex' flexDirection='column' gap={1}>
          {devices?.map((device) => {
            const fullLocationPath = device?.fullLocationPath?.map((loc) => loc?.name)?.join(' / ');
            return (
              <Card
                key={`${device?.serialNumber}-${device?.nickname}`}
                sx={{ width: '100%', cursor: 'pointer', textDecoration: 'none' }}
                component={RouterLink}
                color='inherit'
                to={`/devices/${device?.serialNumber}`}
                onClick={(e) => {
                  if (device?.account) {
                    const { target, nativeEvent } = e;
                    const clonedNativeEvent = new MouseEvent('click', nativeEvent);
                    e.stopPropagation();
                    e.preventDefault();
                    switchOrg(device.account);
                    target.dispatchEvent(clonedNativeEvent);
                    return;
                  }
                  dispatchToast({
                    severity: ToastNotificationSeverityTypeEnum.WARNING,
                    title: 'Device details unavailable',
                    message: 'Please register this device to an org to view its details',
                  });
                  e.preventDefault();
                }}
              >
                <CardHeader
                  avatar={<DeviceIconCell deviceType={device?.type ?? ''} />}
                  title={
                    device?.nickname ? (
                      <Typography fontWeight='bold' color='gray' variant='body2'>
                        {device?.nickname}
                      </Typography>
                    ) : (
                      <Typography variant='body2'>{device?.serialNumber}</Typography>
                    )
                  }
                  subheader={
                    device?.nickname ? (
                      <Typography variant='body2'>{device?.serialNumber}</Typography>
                    ) : (
                      void 0
                    )
                  }
                  action={
                    copied === device?.serialNumber ? (
                      <IconButton size='small' onClick={(e) => e.preventDefault()}>
                        <CheckIcon color='success' sx={{ height: 16, width: 16 }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => copyText(e, device?.serialNumber ?? '')}
                        size='small'
                      >
                        <CopyIcon sx={{ height: 16, width: 16 }} />
                      </IconButton>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <DeviceLocationCell
                        fullLocation={fullLocationPath ? fullLocationPath : 'No Location'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DeviceOrgCell
                        account={device?.account}
                        serialNumber={device?.serialNumber}
                        postRegisterDevice={postRegisterDevice}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DeviceFirmwareCell firmware={device?.firmwareVersion} />
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <DeviceConnectivityCell connectivity={device?.connectivity} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <DeviceStateCell state={device?.state} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        {totalCount === devices.length && (
          <Box sx={{ marginY: 3 }}>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              align='center'
              variant='body1'
            >
              You have viewed all the devices that match your current filters
            </Typography>
            <Typography variant='body2' align='center'>
              Modify the selected filters to find more devices
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Fragment>
  );
}
